<template>
    <div class="quarterWidthBox flex justify-between items-center align-middle max-h-16" v-if="global.merlin.clientCodes.length > 1">
        <label>Filter by Code : </label>
        <select v-model="global.merlin.selectedClientCode" @change="()=> codeSelectedChanged($event)" 
          
          
          class="items-center py-2.5 px-4 -medium text-gray-900 bg-gray-100 border border-gray-300 hover:bg-gray-200 rounded-md" 
        
        
          
          >

          <!-- class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 ml-2 p-2" -->


          <option v-for="(code, index) in global.merlin.clientCodes" :key="code" :value="code">{{ code }}</option>
        </select>
    </div>
</template>




<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "@/store/useGlobalState";

export default defineComponent({
    name: "ClientPicker",
    created(){
      const global = useGlobalState();
    },
    data : function() {
      return {
        merlinData : "",
        error: "",
        global : useGlobalState(),
        srcButton : {
          disabled: false,
          innerText: 'Update Merlin',
        },
        rowsToExpand: []
      };
    },

    methods :{
        codeSelectedChanged(event){
            this.onChangeDataRequest();
        },
        async onChangeDataRequest() {
          
          try {
              let fetchMerlinInfo = await this.global.axios.post(`/merlin/search_by_code`, {
                code: this.global.merlin.selectedClientCode
              })
          
            if (fetchMerlinInfo.status !== 200) {
                console.log(`Error ${fetchMerlinInfo.status}: Merlin data not loaded through search by code fetch.`)
                this.error = `${fetchMerlinInfo.status} error`;
            } else {
                let recievedMerlinData = fetchMerlinInfo.data.returnedData
            
                if (recievedMerlinData.length == 0) {
                    this.error = "No Merlin data returned.";
                } else {
                    let sortedData = this.sortThings(recievedMerlinData);
                    this.global.merlin.merlinData = sortedData;
                }
            }
          } catch (error) {
            console.error(error);
            this.error = "An error occurred while fetching Merlin data.";
          }
        },
        
        sortThings(toSort){
          let sorted = toSort.sort((a, b) => {
            const [prefixA, suffixA] = a.id.split('-');
            const [prefixB, suffixB] = b.id.split('-');

            const numberA = parseInt(suffixA.slice(1), 10);
            const numberB = parseInt(suffixB.slice(1), 10);

            if (prefixA < prefixB) return -1;
            if (prefixA > prefixB) return 1;
          
            // If the prefixes are equal, sort by the number
            return numberA - numberB;
        });
        return sorted;
      }
    }
});
</script>
  