<template>
  <div class="general--xPadding">
    <div class="flex flex-row justify-between mb-0 items-end">
      <h4 class="font-medium mb-0">Create a new election</h4>
      <img :src="SvgClose" alt="my-logo" @click="this.$emit('triggerCloseAllDropdowns')" class="w-10 h-10 cursor-pointer"/>
    </div>
    <p class="text-left italic general--topPadding--half">Creating a new election will automatically archieve the previously active election, and set this new election as active. Your user account will also be assigned to this election.</p>

    <h4 class="text-left general--topPadding">Enter election details :</h4>
    <div>
      <form class="text-left font-medium overflow-hidden flex flex-col" @submit.prevent="saveSettings">
        <div class="flex">
          <label for="first_name" class="block text-md font-light text-gray-900 dark:text-white w-1/6 self-center">Election name</label>
          <input v-model="newElection.name" type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
          <span class="text-red-500" v-if="!newElection.name && showError">Required!</span>
        </div>

        <div class="flex general--topPadding--half">
          <label for="first_name" class="block text-md font-light text-gray-900 dark:text-white w-1/6 self-center">Location</label>
          <input v-model="newElection.location" type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
          <span class="text-red-500" v-if="!newElection.location && showError">Required!</span>
        </div>

        <div class="flex general--topPadding--half">
          <label for="first_name" class="block text-md font-light text-gray-900 dark:text-white w-1/6 self-center">Election Date</label>
          <input v-model="newElection.date.year" type="number"  min="1" placeholder="yyyy" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/6 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
          <span class="text-red-500" v-if="!newElection.date.year && showError">Required!</span>
          <input v-model="newElection.date.month" type="number"  min="1" max="12" placeholder="mm" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/6 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
          <span class="text-red-500" v-if="!newElection.date.month && showError">Required!</span>
          <input v-model="newElection.date.day" type="number"  min="1" max="31" placeholder="dd" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  w-1/6 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required>
          <span class="text-red-500" v-if="!newElection.date.day && showError">Required!</span>
        </div>

        <div class="general--topPadding--half general--bottomPadding">
          <button v-if="!saving && !error" @click="attemptSave" class="button--submit w-1/6">Save</button>
          <button v-else-if="saving" disabled class="button--submit">Saving...</button>
          <button v-else-if="error" @click="attemptSave">Error, Retry?</button>
        </div>

      </form>
    </div>
  </div>

</template>
  
<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "./../../store/useGlobalState";
import SvgClose from '../../assets/close.svg';

export default defineComponent({
  name: "CreateElection",
  emits: ['triggerGetElectionStatus', 'triggerCloseAllDropdowns'],
  props: {
    environment: String, 
    createElectionSelected: {
        type: Boolean,
        required: true
    }
  },
  setup() {
    return {
      SvgClose
    }
  },
  data : function () {
    return {
      global : useGlobalState(),
      saving: false,
      showError: false,
      error: false,
      newElection : {
        name: '',
        location: '',
        date: {
          year : '',
          month : '',
          day : ''
        }
      }
    };
  },
  methods: {
    async attemptSave() {
        if(!this.newElection.name || !this.newElection.location) {
            this.showError = true;
            return;
        }
        
        this.showError = false;
        this.saving = true;
        await this.createNewElection();
        this.saving = false;
    },

    async createNewElection() {
        console.log(this.newElection)
        try {
            console.log("trying to create new election in eia in :" + this.environment)
            
            let fetchSecureConnection = await this.global.axios.post(`/tallypoint/create_election`, {newElectionInfo : this.newElection}, {
              headers: {
              'X-Target-Environment': this.environment,
            }});
            console.log(fetchSecureConnection);
            if (fetchSecureConnection.status === 200) {
                console.log("Election created successfully")
                this.$emit('trigger-get-election-status');
                this.$emit('trigger-close-all-dropdowns');
                this.resetCreateElection();
                this.error = false;
            } else {
                throw new Error(`HTTP error ${fetchSecureConnection.status}`);
            }
        } catch (error) {
            console.error("Error fetching election info:", error);
            this.error = true;
        }
    },

    resetCreateElection(){
      this.newElection.name =  '';
      this.newElection.location =  '';
      this.newElection.date.year =  '';
      this.newElection.date.month =  '';
      this.newElection.date.day =  '';
      this.createElectionSelected = true;
    }
  }
});
</script>