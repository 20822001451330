<template>
  <main class="card--fullWidth" style="padding-left: 0!important; padding-right: 0!important; padding-top: 0!important;">
    <table class="w-full tw-full text-sm text-left text-gray-500 dark:text-gray-400 rounded-lg" v-if="global.merlin && global.merlin.headerDefaultOrder">
      <thead class="merlinTableThead text-base text-white bg-gray-600 rounded-lg sticky top-20" style="height: 65px!important;">
        <tr style="height: 65px!important;">
          <template v-for="singleHeader in headers">
            <th  
              scope="col" 
              class="px-6 py-3"
              >
              <div class="flex flex-row items-center">
                <h5 class="pr-3">{{ singleHeader }}</h5>
                <svg v-if="singleHeader !== currentSortingMethod" @click="reorderTable(singleHeader)" width="19" height="16" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top: 1px;" class="cursor-pointer hover-fill">
                  <rect y="3" width="16" height="2" fill="#FFFFFF"/>
                  <rect y="6" width="16" height="2" fill="#FFFFFF"/>
                  <rect y="9" width="16" height="2" fill="#FFFFFF"/>
                  <path d="M8 0L10.5981 2.25H5.40192L8 0Z" fill="#FFFFFF"/>
                  <path d="M8 14L10.5981 11.75H5.40192L8 14Z" fill="#FFFFFF"/>
                </svg>
                <svg v-else-if="singleHeader === currentSortingMethod && currentSortingDirection" @click="reorderTable(singleHeader)" width="26" height="16" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top: 1px;" class="cursor-pointer hover-unfill">
                  <rect width="16" height="2" transform="matrix(1 0 0 -1 7 11)" fill="#FFFFFF"/>
                  <rect width="8" height="2" transform="matrix(1 0 0 -1 7 8)" fill="#FFFFFF"/>
                  <rect width="4" height="2" transform="matrix(1 0 0 -1 7 5)" fill="#FFFFFF"/>
                  <rect width="12" height="2" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 4 12)" fill="#FFFFFF"/>
                  <path d="M3 14L5.59808 11.75H0.401924L3 14Z" fill="#FFFFFF"/>
                </svg>
                <svg v-else @click="reorderTable(singleHeader)" width="26" height="16" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top: 1px;" class="cursor-pointer hover-unfill">
                  <rect x="7" y="3" width="16" height="2" fill="#FFFFFF"/>
                  <rect x="7" y="6" width="8" height="2" fill="#FFFFFF"/>
                  <rect x="7" y="9" width="4" height="2" fill="#FFFFFF"/>
                  <rect x="4" y="2" width="12" height="2" transform="rotate(90 4 2)" fill="#FFFFFF"/>
                  <path d="M3 0L5.59808 2.25H0.401924L3 0Z" fill="#FFFFFF"/>
                </svg>
              </div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody class="mt-10">
        <template v-for="(user, i) in userList" v-bind:key="user.username" class="inline-block">
          <tr :class="rowsToExpand.includes(user.username) ? 'bg-zinc-300 border-t-2 border-gray-400 border-b-0' : 'bg-white border-b hover:bg-gray-300 cursor-pointer'">
            <template v-for="singleHeader in headers">
              <td
                @click="toggleRowExpand(user.username, i)"
                :class="singleHeader === 'username' ? 'px-6 py-4 font-bold text-gray-900 whitespace-nowrap' : 'px-6 py-3 text-gray-800'"
                scope="row"
              >
              {{ getNestedProperty(user, singleHeader) }}
              </td>
            </template>
          </tr>
          <tr v-if="rowsToExpand.includes(user.username)" class="bg-gray-300 mb-10">
          <td colspan="100%" class="pt-5">
            <div class="flex w-full pr-5">
            <div class="flex flex-col pl-5 w-48">
                <button v-for="button in user.dropdownButtons"
                  :class="user.dropdownSelected === button ? 'bg-gray-100 text-black border-2 border-orange-500  border-opacity-70 border-r-gray-100 rounded-r-none' : 'bg-gray-300 text-gray-800 border-2 border-gray-500 border-r-orange-500  border-opacity-70'"
                  class="hover:bg-gray-100 font-semibold py-2 px-4 shadow rounded-l text-left transition-colors duration-200"
                  @click="dropdownButtonSelector(user.username, button, i)"
                >
                  {{button}}
                </button>
                <div class="mb-5 grow bg-zinc-300 border-zinc-300 border-2 border-r-orange-500 border-opacity-70 "></div>
            </div>

            <div v-if="user.dropdownSelected === 'General'" class="pl-5 pb-1 bg-gray-100  text-black border-2 border-orange-500 border-opacity-70 rounded-tl-none border-l-gray-100 shadow rounded mr-5 mb-5 flex justify-between w-full">
              <div class="w-10/12 pb-1">
                <ul class="pb-1">
                  <h4>General User Infomation</h4>
                  <li class="flex"><label class="w-32">Username:</label><p class="pl-5 font-semibold">{{ user.username }}</p></li>
                  <li class="flex"><label class="w-32">Password:</label><p class="pl-5 font-semibold">{{ user.password }}</p></li>
                  <li class="flex"><label class="w-32">Email:</label><p class="pl-5 font-semibold">{{ user.email }}</p></li>
                  <li class="flex"><label class="w-32">First Name:</label><p class="pl-5 font-semibold">{{ user.firstName }}</p></li>
                  <li class="flex"><label class="w-32">Last Name:</label><p class="pl-5 font-semibold">{{ user.lastName }}</p></li>
                </ul>
                <button>Delete User Account</button>
              </div>

            
              <div class="flex justify-end w-2/12">
                <button @click="closeRowExpand(user.username)"
                class="bg-gray-300 fill-gray-800 border-2 border-gray-300 border-l-orange-500 border-b-orange-500  border-opacity-70 shadow rounded h-12 hover:bg-gray-100 hover:fill-black transition-colors duration-200">
                <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 96 960 960" width="42"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>
                </button>
              </div>
            </div>

            <div v-if="user.dropdownSelected === 'tallypoint'" class="pl-5 pb-1 bg-gray-100  text-black border-2 border-orange-500 border-opacity-70 rounded-tl-none border-l-gray-100 shadow rounded mr-5 mb-5 flex justify-between w-full">
              <ul class="w-5/12 pb-1" v-if="user.roles.includes(`tallypoint`) || user.roles.includes(`tallypoint_admin`)">
                <h4>Production</h4>
                <li class="flex"><label class="w-32">Client Code:</label><p class="pl-5 font-semibold">{{ user.groups.tallypoint[0] }}</p></li>
                <li class="flex"><label class="w-32">Regular Access:</label><p class="pl-5 font-semibold">{{ user.roles.includes("tallypoint") ? "true" : "false" }}</p></li>
                <li class="flex"><label class="w-32">Admin Access:</label><p class="pl-5 font-semibold">{{ user.roles.includes("tallypoint_admin") ? "true" : "false" }}</p></li>

              </ul>
            
              <ul class="w-5/12 pb-1" v-if="user.roles.includes(`tallypoint_uat`) || user.roles.includes(`tallypoint_uat_admin`)">
                <h4>UAT</h4>
                <li class="flex"><label class="w-32">Client Code:</label><p class="pl-5 font-semibold">{{ user.groups.tallypoint_uat[0] }}</p></li>
                <li class="flex"><label class="w-32">Regular Access:</label><p class="pl-5 font-semibold">{{ user.roles.includes("tallypoint_uat") ? "true" : "false" }}</p></li>
                <li class="flex"><label class="w-32">Admin Access:</label><p class="pl-5 font-semibold">{{ user.roles.includes("tallypoint_uat_admin") ? "true" : "false" }}</p></li>
              </ul>
            
              <div class="flex justify-end w-2/12">
                <button @click="closeRowExpand(user.username)"
                class="bg-gray-300 fill-gray-800 border-2 border-gray-300 border-l-orange-500 border-b-orange-500  border-opacity-70 shadow rounded h-12 hover:bg-gray-100 hover:fill-black transition-colors duration-200">
                <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 96 960 960" width="42"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>
                </button>
              </div>
            </div>
            <div v-if="user.dropdownSelected === 'merlin'" class="pl-5 pb-1 bg-gray-100  text-black border-2 border-orange-500 border-opacity-70 rounded-tl-none border-l-gray-100 shadow rounded mr-5 mb-5 flex w-full">
              <ul class="w-5/12 pb-1">
                <h4>Merlin Access</h4>
                <li class="flex"><label class="w-32">Client Code:</label><p class="pl-5 font-semibold">{{ user.groups.merlin }}</p></li>
              </ul>
            
              <ul class="w-5/12 pb-1">
              </ul>
            
              <div class="flex justify-end w-2/12">
                <button @click="closeRowExpand(user.username)"
                class="bg-gray-300 fill-gray-800 border-2 border-gray-300 border-l-orange-500 border-b-orange-500  border-opacity-70 shadow rounded h-12 hover:bg-gray-100 hover:fill-black transition-colors duration-200">
                <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 96 960 960" width="42"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>
                </button>
              </div>
            </div>
            <div v-if="user.dropdownSelected === 'eia'" class="pl-5 pb-1 bg-gray-100  text-black border-2 border-orange-500 border-opacity-70 rounded-tl-none border-l-gray-100 shadow rounded mr-5 mb-5 flex w-full">
              <ul class="w-5/12 pb-1">
                <h4>EIA Access</h4>
                <li class="flex"><label class="w-32">Basic Access:</label><p class="pl-5 font-semibold">{{ user.roles.includes("eia") ? "true" : "false" }}</p></li>
                <li class="flex"><label class="w-32">Admin Access:</label><p class="pl-5 font-semibold">{{ user.roles.includes("eia_admin") ? "true" : "false" }}</p></li>
                <li class="flex"><label class="w-32">System Admin Access:</label><p class="pl-5 font-semibold">{{ user.roles.includes("eia_system_admin") ? "true" : "false" }}</p></li>
              </ul>
            
              <ul class="w-5/12 pb-1">
              </ul>
            
              <div class="flex justify-end w-2/12">
                <button @click="closeRowExpand(user.username)"
                class="bg-gray-300 fill-gray-800 border-2 border-gray-300 border-l-orange-500 border-b-orange-500  border-opacity-70 shadow rounded h-12 hover:bg-gray-100 hover:fill-black transition-colors duration-200">
                <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 96 960 960" width="42"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>
                </button>
              </div>
            </div>
            </div>
          </td>
        </tr>
        </template>
      </tbody>
    </table>
  </main>
</template>

<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "./../../store/useGlobalState";


export default defineComponent({
  name: "TheAdminUserTable",
  data : function () {
    return {
      global : useGlobalState(),
      headers: [
        "username",
        "createdTimestamp",
        "tallypoint",
        "merlin",
        "eia",
        "activeSession"
      ],
      currentSortingMethod: "username",
      currentSortingDirection: true,
      expandedRowsStyling: [],
      rowsToExpand: [],
      historiesToDisplay: [],
      interactiveButtons: [],
    };
  },
  props: {
    userList: Array,
    closeUserPopup: Boolean,
    userProfile: Object
  },
  methods:{
    dropdownButtonSelector(id, buttonType, userIndex) {
        // Note - to add buttons to dropdown change authUtility.createUserButtons in the backend

        const index = this.rowsToExpand.indexOf(id);

        if (index === -1) {
          return;
        }

        if (buttonType === "Edit User"){
          this.$emit('set-user-profile', this.userList[userIndex]);
          this.$emit('open-popup');
        } else {
          this.userList[userIndex].dropdownSelected = buttonType;
        }

    },
    closeRowExpand(id){
      this.rowsToExpand = this.rowsToExpand.filter((rowId) => rowId !== id);
      this.historiesToDisplay = this.historiesToDisplay.filter((rowId) => rowId !== id);
    },
    toggleRowExpand(id, toggledRowIndex){
      if (this.rowsToExpand.includes(id)) {
        return
      } else {
      
        // Hacky code to create a fake expandedRowsStyling entry so if user selects a row to expand that is not in order then the styling will still work
        if (this.expandedRowsStyling.length < this.userList.length){
          for (let index = 0; index < this.userList.length; index++) {

            let dropdownButtonStyling = {};
            
            this.userList[index].dropdownButtons.forEach(button => {
              dropdownButtonStyling[button] = false;
            });

            this.expandedRowsStyling.push(dropdownButtonStyling);
          }
        }

        Object.values(this.expandedRowsStyling[toggledRowIndex])[0] = true; // returns 'someVal'

        this.rowsToExpand.push(id);
      
      }
    },
    getNestedProperty(obj, singleHeader) {
      const [head, ...tail] = singleHeader.split('-');
      let value = tail.reduce((prev, cur) => prev && prev[cur], obj[head]);

      if (singleHeader === 'createdTimestamp') {
        value = this.formatDate(value);
      } else if (singleHeader === 'tallypoint' || singleHeader === 'merlin') {
        value = value ? 'true' : 'false';
      } else if (singleHeader === 'eia') {
        value = obj.roles.includes('eia');
      }

      return value;
    },
    reorderTable(headerClicked){
          if(headerClicked == this.currentSortingMethod){
            this.currentSortingDirection = !this.currentSortingDirection;
          }
          let sortedData = this.sortThingsGeneral(this.userList, headerClicked);
          this.global.merlin.merlinData = sortedData;

          this.currentSortingMethod = headerClicked;
    },
    formatDate(dateTime){
      let formattedDate = new Date(dateTime).toString();
      return formattedDate.replace(/ GMT[+\-]\d{4} \([^\)]+\)/, '');
    },
    sortThingsGeneral(toSort, sortBy) {
          const sortKeys = sortBy.split('-'); // e.g. ['tags', 'kind']

          toSort.sort((a, b) => {
            let valueA = a, valueB = b;

            // Navigate through the nested fields
            for (let key of sortKeys) {
                valueA = valueA[key];
                valueB = valueB[key];
            }

            // Make sure the values are strings for comparison
            valueA = String(valueA);
            valueB = String(valueB);

            if (this.currentSortingDirection) { // Regular order
                if (valueA < valueB) return -1;
                if (valueA > valueB) return 1;
            } else { // Reverse order
                if (valueA < valueB) return 1;
                if (valueA > valueB) return -1;
            }

            return 0;
          });
        return toSort;
      },
  }
});
</script>

<style scoped>
.hover-fill:hover rect,
.hover-fill:hover path {
    fill: rgb(249 115 22);
    transition-duration: 250ms;
}

.hover-unfill rect, .hover-unfill path {
  fill: rgb(249 115 22);

}

</style>