<template v-if="showPopup">
  <main>
    <div class="flex w-full pr-5">
      <div class="flex flex-col pl-5 w-48" >
        <button
          :class="columnToExpand === 'general' ? 'bg-gray-100 text-black border-2 border-orange-500  border-opacity-70 border-r-gray-100 rounded-r-none' : 'bg-gray-300 text-gray-800 border-2 border-gray-500 border-r-orange-500  border-opacity-70'"
          class="hover:bg-gray-100 font-semibold py-2 px-4 shadow rounded-l text-left transition-colors duration-200"
          @click="dropdownButtonSelector('general')"
        >
          General Info
        </button>
        <button v-for="button in dropdownButtons"
          :class="columnToExpand === button ? 'bg-gray-100 text-black border-2 border-orange-500  border-opacity-70 border-r-gray-100 rounded-r-none' : 'bg-gray-300 text-gray-800 border-2 border-gray-500 border-r-orange-500  border-opacity-70'"
          class="hover:bg-gray-100 font-semibold py-2 px-4 shadow rounded-l text-left transition-colors duration-200"
          @click="dropdownButtonSelector(button)"
        >
          {{button}}
        </button>
        <div class="mb-5 grow bg-transparent border-transparent border-2 border-r-orange-500 border-opacity-70 "></div>
        </div>
        <div v-if="columnToExpand === 'general' && showPopup" class="pl-5 pb-1 bg-gray-100  text-black border-2 border-orange-500 border-opacity-70 rounded-tl-none border-l-gray-100 shadow rounded mr-5 mb-5 flex w-full">
          <div class="flex justify-start flex-col w-10/12">

            <h4 class="text-left font-medium">General User Infomation</h4>

            <form class="w-10/12 pb-1 flex flex-col align-start items-start">
              <!-- Username -->
              <div id ='input-container'>
                <ejs-textbox id='textbox' floatLabelType="Auto" placeholder="Username (required)" v-model="userProfile.info.username"></ejs-textbox>
                <span v-if="formErrors.username" class="text-red-500">{{ formErrors.username }}</span>
              </div>
            
              <!-- Password -->
              <div id ='input-container flex'>
                <ejs-textbox id='textbox' floatLabelType="Auto" placeholder="Password" v-model="userProfile.info.credentials.value"></ejs-textbox>
                <span v-if="formErrors.password" class="text-red-500">{{ formErrors.password }}</span>
                <ejs-checkbox label='Temporary Password ?' v-model="userProfile.info.credentials.temporary" v-if="userProfile.info.credentials.value"></ejs-checkbox>
              </div>
            
              <!-- Email -->
              <div id ='input-container'>
                <ejs-textbox id='textbox' floatLabelType="Auto" placeholder="Email" v-model="userProfile.info.email"></ejs-textbox>
                <span v-if="formErrors.email" class="text-red-500">{{ formErrors.email }}</span>
              </div>
            
              <!-- First Name -->
              <div id ='input-container'>
                <ejs-textbox id='textbox' floatLabelType="Auto" placeholder="First Name" v-model="userProfile.info.firstName"></ejs-textbox>
                <span v-if="formErrors.firstName" class="text-red-500">{{ formErrors.firstName }}</span>
              </div>
            
              <!-- Last Name -->
              <div id ='input-container'>
                <ejs-textbox id='textbox' floatLabelType="Auto" placeholder="Last Name" v-model="userProfile.info.lastName"></ejs-textbox>
                <span v-if="formErrors.lastName" class="text-red-500">{{ formErrors.lastName }}</span>
              </div>
            </form>
          </div>

        
          <div class="flex justify-end w-2/12">
            <button @click="closePopup()"
            class="bg-gray-300 fill-gray-800 border-2 border-gray-300 border-l-orange-500 border-b-orange-500  border-opacity-70 shadow rounded h-12 hover:bg-gray-100 hover:fill-black transition-colors duration-200">
            <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 96 960 960" width="42"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>
            </button>
          </div>
        </div>
        <div v-if="columnToExpand === 'tallypoint' && showPopup" class="pl-5 pb-1 bg-gray-100  text-black border-2 border-orange-500 border-opacity-70 rounded-tl-none border-l-gray-100 shadow rounded mr-5 mb-5 flex w-full">
          <div class="w-10/12 pb-1 flex flex-col align-start">
            <h4 class="text-left font-medium">Tallypoint Access</h4>

            <!-- Production Section -->
            <div class="mb-4 w-full">
                <h2 class="text-lg font-semibold mb-2 text-left">Production</h2>
                <div class="flex flex-col items-start w-full">
                  <div class="form-group">
                    <div class="col-md-6 w-full flex">
                      <div class="control_wrapper w-40">
                        <ejs-multiselect id='multiselect' :dataSource='existingHosts.tallypoint.production' :allowCustomValue='true' v-model="userProfile.tallypoint.production.hosts" placeholder="Enter the host name"></ejs-multiselect>
                      </div>
                      <div>
                        <ejs-checkbox label='Admin' v-model="userProfile.tallypoint.production.admin"></ejs-checkbox>
                      </div>
                    </div>
                    
                    <div class ='flex flex-col items-start text-left' v-if="!doesHostExist('tallypoint','production')">
                      <div v-if="userProfile.tallypoint.production.hosts.length > 0">
                        <span v-if="!doesHostExist('tallypoint','production')" class="text-red-500">This host does not exist. A new host will be created with this name.</span>
                      </div>
                      <div id ='input-container' v-if="userProfile.tallypoint.production.hosts.length > 0">
                          <ejs-textbox id='textbox' floatLabelType="Auto" placeholder="Host's public site path" v-model="userProfile.tallypoint.production.publicSitePath"></ejs-textbox>
                          <span v-if="formErrors.tallypointProductionPublicSitePath" class="text-red-500">{{ formErrors.tallypointProductionPublicSitePath }}</span>
                      </div>
                    </div>
                  </div>
                </div>
            </div>

            <!-- UAT Section -->
            <div class="mb-4 w-full">
                <h2 class="text-lg font-semibold mb-2 text-left">UAT</h2>
                <div class="flex flex-col items-start w-full">
                  <div class="form-group">
                    <div class="col-md-6 w-full flex">
                      <div class="control_wrapper w-40">
                        <ejs-multiselect id='multiselect' :dataSource='existingHosts.tallypoint.uat' :allowCustomValue='true' v-model="userProfile.tallypoint.uat.hosts" placeholder="Enter the host name"></ejs-multiselect>
                      </div>
                      <div>
                        <ejs-checkbox label='Admin' v-model="userProfile.tallypoint.uat.admin"></ejs-checkbox>
                      </div>
                    </div>
                    
                    <div class ='flex flex-col items-start text-left' v-if="!doesHostExist('tallypoint','uat')">
                      <div v-if="userProfile.tallypoint.uat.hosts.length > 0">
                        <span v-if="!doesHostExist('tallypoint','uat')" class="text-red-500">This host does not exist. A new host will be created with this name.</span>
                      </div>
                      <div id ='input-container' v-if="userProfile.tallypoint.uat.hosts.length > 0">
                          <ejs-textbox id='textbox' floatLabelType="Auto" placeholder="Host's public site path" v-model="userProfile.tallypoint.uat.publicSitePath"></ejs-textbox>
                          <span v-if="formErrors.tallypointUatPublicSitePath" class="text-red-500">{{ formErrors.tallypointUatPublicSitePath }}</span>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        
          </div>

        
          <div class="flex justify-end w-2/12">
            <button @click="closePopup()"
            class="bg-gray-300 fill-gray-800 border-2 border-gray-300 border-l-orange-500 border-b-orange-500  border-opacity-70 shadow rounded h-12 hover:bg-gray-100 hover:fill-black transition-colors duration-200">
            <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 96 960 960" width="42"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>
            </button>
          </div>
        </div>
        <div v-if="columnToExpand === 'merlin' && showPopup" class="pl-5 pb-1 bg-gray-100  text-black border-2 border-orange-500 border-opacity-70 rounded-tl-none border-l-gray-100 shadow rounded mr-5 mb-5 flex w-full justify-between">
          <div class="flex flex-col">
            <h4 class="text-left font-medium">Merlin Access</h4>
            <div class="w-10/12 pb-1">
                <ejs-multiselect id='multiselect' :dataSource='existingHosts.merlin' :allowCustomValue='true' v-model="userProfile.merlin.hosts" placeholder="Enter Merlin host(s)"></ejs-multiselect>
                <!-- <span v-if="!doesHostExist('merlin', null).length > 0 && userProfile.merlin.hosts.length > 0" class="text-red-500">djshna</span> -->
                <span v-if="doesHostExist('merlin', null).length > 0" class="text-red-500">The following host(s) do not exist : {{doesHostExist('merlin', null)}}. The new host(s) will be created with these names. </span>
            </div>
          </div>


          <div class="flex justify-end w-2/12">
            <button @click="closePopup()"
            class="bg-gray-300 fill-gray-800 border-2 border-gray-300 border-l-orange-500 border-b-orange-500  border-opacity-70 shadow rounded h-12 hover:bg-gray-100 hover:fill-black transition-colors duration-200">
            <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 96 960 960" width="42"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>
            </button>
          </div>
        </div>
        <div v-if="columnToExpand === 'eia' && showPopup" class="pl-5 pb-1 bg-gray-100  text-black border-2 border-orange-500 border-opacity-70 rounded-tl-none border-l-gray-100 shadow rounded mr-5 mb-5 flex w-full">
          <div class="w-10/12 pb-1">
            <h4 class="text-left font-medium">EIA Access</h4>
            <div class="flex flex-col items-start">
              <ejs-checkbox label='General Access' v-model="userProfile.eia.general"></ejs-checkbox>
              <ejs-checkbox label='Admin Access' v-model="userProfile.eia.admin"></ejs-checkbox>
              <ejs-checkbox label='System Admin Access' v-model="userProfile.eia.system_admin"></ejs-checkbox>
            </div>
            <span v-if="formErrors.eiaAdmin" class="text-red-500">{{ formErrors.eiaAdmin }}</span>
          </div>
        
        
          <div class="flex justify-end w-2/12">
            <button @click="closePopup()"
            class="bg-gray-300 fill-gray-800 border-2 border-gray-300 border-l-orange-500 border-b-orange-500  border-opacity-70 shadow rounded h-12 hover:bg-gray-100 hover:fill-black transition-colors duration-200">
            <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 96 960 960" width="42"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>
            </button>
          </div>
        </div>
    </div>
    <div>
          <button v-if="showPopup && sumbitButtonLabel === `Create User`" @click="createNewUser()" type="submit" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Create New User</button>
          <button v-if="showPopup && sumbitButtonLabel === `Update User`" @click="createNewUser()" type="submit" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Submit Changes</button>
          <button>Delete User</button>
    </div>
  </main>
</template>

<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "./../../store/useGlobalState";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { CheckBoxComponent } from "@syncfusion/ej2-vue-buttons";





export default defineComponent({
  name: "TheAdminUserCreator",
  data : function () {
    return {
      global : useGlobalState(),
      showPopup: this.showUserPopup,
      formErrors: {},
      dropdownButtons: ["tallypoint", "merlin", "eia"],
      columnToExpand: "general",
      existingHosts: {
        tallypoint : {
          production: [],
          uat: [],
        },
        merlin :  [],
      }
    };
  },
  props: {
    userList: Array, 
    showUserPopup: Boolean,
    userProfile: Object,
    sumbitButtonLabel: String
  },
  components:{
    'ejs-multiselect' : MultiSelectComponent,
    'ejs-textbox' : TextBoxComponent,
    "ejs-checkbox": CheckBoxComponent
  },
  async created() {
    let fetchExistingHosts = await this.global.axios.get(`/admin/existing_hosts`);
    if (fetchExistingHosts.status === 200) {
      console.log(fetchExistingHosts.data)
      this.existingHosts.tallypoint.production = fetchExistingHosts.data.tallypoint;
      this.existingHosts.tallypoint.uat = fetchExistingHosts.data.tallypoint_uat;
      this.existingHosts.merlin = fetchExistingHosts.data.merlin;
    }
  },
  methods:{
    async createHost() {
      console.log("createHost frontned run ...")
      await this.global.axios.post(`/admin/create_tallypoint_host`);

    },
    changeSelectInput(enviroment, value) {  
      console.log("changeSelectInput")
      this.userProfile.tallypoint[enviroment].hosts = value;
    },
    doesHostExist(app, enviroment) {

      if (app === 'merlin') {
        console.log(this.userProfile[app].hosts)
        const newMerlinHosts = this.userProfile[app].hosts.filter(item => !this.existingHosts.merlin.includes(item));

        return newMerlinHosts

      } else {
        // Returns boolean value to trigger warning for user that host will need to created
        return this.existingHosts[app][enviroment].includes(this.userProfile[app][enviroment].hosts[0]);
      }
    },
    dropdownButtonSelector(buttonType) {
        if (buttonType !== this.columnToExpand) {
          this.columnToExpand = buttonType;
        } else {
          return;
        }
    },
    closePopup(){
      this.columnToExpand = "tallypoint";
      this.$emit('close-popup');

    },
    validateForm() {
      console.log("validateForm")
      this.formErrors = {}; // Reset errors

      // If tallypoint production admin is true, then there must be at least 1 host
      if (this.userProfile.tallypoint.production.admin && this.userProfile.tallypoint.production.hosts.length === 0) {
        this.formErrors.tallypointProductionHost = 'A host is required for production admin.';
      } 
      
      if (this.userProfile.tallypoint.production.hosts.length > 1) {
        this.formErrors.tallypointProductionHost = 'Only 1 host can be selected for production admin.';
      }

      if (this.userProfile.tallypoint.production.hosts.length > 0) {
        if (this.existingHosts.tallypoint.production.includes(this.userProfile.tallypoint.production.hosts[0])) {
          this.userProfile.tallypoint.production.newHost = false;
        } else {
          this.userProfile.tallypoint.production.newHost = true;
          if (!this.userProfile.tallypoint.production.publicSitePath) {
            this.formErrors.tallypointProductionPublicSitePath = 'A public site path is required for a new host.';
          }
        }
      } 





      // If tallypoint uat admin is true, then there must be at least 1 host
      if (this.userProfile.tallypoint.uat.admin && this.userProfile.tallypoint.uat.hosts.length === 0) {
        this.formErrors.tallypointUatHost = 'A host is required for uat admin.';
      } 
      
      if (this.userProfile.tallypoint.uat.hosts.length > 1) {
        this.formErrors.tallypointUatHost = 'Only 1 host can be selected for uat admin.';
      }

      if (this.userProfile.tallypoint.uat.hosts.length > 0) {
        if (this.existingHosts.tallypoint.uat.includes(this.userProfile.tallypoint.uat.hosts[0])) {
          this.userProfile.tallypoint.uat.newHost = false;
        } else {
          this.userProfile.tallypoint.uat.newHost = true;
          if (!this.userProfile.tallypoint.uat.publicSitePath) {
            this.formErrors.tallypointUatPublicSitePath = 'A public site path is required for a new host.';
          }
        }
      } 


      // If eia.admin is true, then eia.general must be true
      if (this.userProfile.eia.admin && !this.userProfile.eia.general) {
        this.formErrors.eiaAdmin = 'General access must be enabled for admin access.';
      }

            // Username is required
      if (!this.userProfile.info.username) {
        this.formErrors.username = 'Username is required.';
        this.columnToExpand = "general";
      }

      return Object.keys(this.formErrors).length === 0; // Form is valid if there are no errors
    },
    async createNewUser(){

      if (this.validateForm()) {



        if (this.userProfile.merlin.hosts.length > 0) {
          const newMerlinHosts = this.userProfile.merlin.hosts.filter(item => !this.existingHosts.merlin.includes(item));

          if (newMerlinHosts.length > 0) {
            this.userProfile.merlin.newHost = true;
          } else {
            this.userProfile.merlin.newHost = false;
          }
        } 

        let fetchCreateUser = await this.global.axios.post(`/admin/create_user`, {"newUser" :this.userProfile});

        if (fetchCreateUser.status === 200) {
          this.userProfile.info.username = "";
          this.userProfile.info.credentials.value = "";
          this.userProfile.info.credentials.temporary = false;
          this.userProfile.info.credentials.email = "";
          this.userProfile.info.credentials.firstName = false;
          this.userProfile.info.credentials.lastName = false;
          this.userProfile.info.tallypoint.production.host = "";
          this.userProfile.info.tallypoint.production.admin = false;
          this.userProfile.info.tallypoint.uat.host = "";
          this.userProfile.info.tallypoint.uat.admin = false;
          this.userProfile.info.eia.general = false;
          this.userProfile.info.eia.admin = false;
          this.userProfile.info.merlin.hosts = [];
          this.columnToExpand = "tallypoint";
          this.showPopup = false;
        }
      }

    }
  }
 
});
</script>

<style scoped>
.hover-fill:hover rect,
.hover-fill:hover path {
    fill: rgb(249 115 22);
    transition-duration: 250ms;
}

.hover-unfill rect, .hover-unfill path {
  fill: rgb(249 115 22);

}

.wrap {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 20px 10px;
    width: 340px;
  }

  .e-float-input.e-control-wrapper .e-float-text::after {
    content: '*';
    color: red;
  }

</style>