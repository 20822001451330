<template>
  <table class="fullWidthBox w-full tw-full text-sm text-left text-gray-500 dark:text-gray-400 rounded-lg" v-if="global.merlin && global.merlin.headerDefaultOrder">
    <thead class="merlinTableThead text-base text-white bg-gray-600 rounded-lg sticky top-20" style="height: 65px!important;">
      <tr style="height: 65px!important;">
        <template v-for="singleHeader in global.merlin.headerDefaultOrder" :key="singleHeader" style="display:none;">
          <th  
            v-if="global.merlin.selectedHeaders.includes(singleHeader) && singleHeader !== 'update'" 
            scope="col" 
            class="px-6 py-3"

            >
            <div class="flex flex-row items-center">
              <h5 class="pr-3">{{ formatHeader(singleHeader) }}</h5>
              <svg v-if="singleHeader !== currentSortingMethod" @click="reorderTable(singleHeader)" width="19" height="16" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top: 1px;" class="cursor-pointer hover-fill">
                <rect y="3" width="16" height="2" fill="#FFFFFF"/>
                <rect y="6" width="16" height="2" fill="#FFFFFF"/>
                <rect y="9" width="16" height="2" fill="#FFFFFF"/>
                <path d="M8 0L10.5981 2.25H5.40192L8 0Z" fill="#FFFFFF"/>
                <path d="M8 14L10.5981 11.75H5.40192L8 14Z" fill="#FFFFFF"/>
              </svg>
              <svg v-else-if="singleHeader === currentSortingMethod && currentSortingDirection" @click="reorderTable(singleHeader)" width="26" height="16" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top: 1px;" class="cursor-pointer hover-unfill">
                <rect width="16" height="2" transform="matrix(1 0 0 -1 7 11)" fill="#FFFFFF"/>
                <rect width="8" height="2" transform="matrix(1 0 0 -1 7 8)" fill="#FFFFFF"/>
                <rect width="4" height="2" transform="matrix(1 0 0 -1 7 5)" fill="#FFFFFF"/>
                <rect width="12" height="2" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 4 12)" fill="#FFFFFF"/>
                <path d="M3 14L5.59808 11.75H0.401924L3 14Z" fill="#FFFFFF"/>
              </svg>
              <svg v-else @click="reorderTable(singleHeader)" width="26" height="16" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top: 1px;" class="cursor-pointer hover-unfill">
                <rect x="7" y="3" width="16" height="2" fill="#FFFFFF"/>
                <rect x="7" y="6" width="8" height="2" fill="#FFFFFF"/>
                <rect x="7" y="9" width="4" height="2" fill="#FFFFFF"/>
                <rect x="4" y="2" width="12" height="2" transform="rotate(90 4 2)" fill="#FFFFFF"/>
                <path d="M3 0L5.59808 2.25H0.401924L3 0Z" fill="#FFFFFF"/>
              </svg>
            </div>
          </th>
          <th  
            v-else-if="global.merlin.selectedHeaders.includes('update') && singleHeader == 'update'" 
            scope="col" 
            class="px-6 py-3 text-end">
            Update Merlin
          </th>
        </template>
      </tr>
    </thead>
    <tbody class="mt-10">
      <template v-for="(merlin, i) in global.merlin.merlinData" v-bind:key="merlin.id" class="inline-block">
        <tr 
          :class="rowsToExpand.includes(merlin.id) ? 'bg-zinc-300 border-t-2 border-gray-400 border-b-0' : 'bg-white border-b hover:bg-gray-300 cursor-pointer'" 
          >

          <template v-for="singleHeader in global.merlin.headerDefaultOrder">
            <td 
              @click="toggleRowExpand(merlin.id, i)"
              v-if="global.merlin.selectedHeaders.includes(singleHeader) && singleHeader !== 'update'"
              :class="singleHeader === 'id' ? 'px-6 py-4 font-bold text-gray-900 whitespace-nowrap' : 'px-6 py-3 text-gray-800'"
              scope="row" >
              {{ getNestedProperty(merlin, singleHeader) }}
            </td>

            <td  
              v-else-if="global.merlin.selectedHeaders.includes('update') && singleHeader == 'update'" 
              scope="row"  
              class="flex justify-end px-6 py-4"
            >

            <!-- <button 
              @click="updateMerin(merlin)"
              :disabled="merlinsUpdating.includes(merlin.id)"
              :class="merlinsUpdating.includes(merlin.id) 
                ? 'bg-orange-500 cursor-not-allowed text-black font-semibold py-2 px-4 border-2 border-gray-600 shadow rounded text-left transition-colors duration-400' 
                : 'bg-grey-300 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border-gray-500 shadow rounded text-left   border-opacity-70 border-2 transition-colors duration-400'"
            >
              {{merlinsUpdating.includes(merlin.id) 
                ? 'Updating' 
                : merlinsUpdating.includes(`Failed ${merlin.id}`) 
                  ? 'Update Failed' 
                  : 'Update'}}
            </button> -->

            <button 
              @click="updateMerin(merlin)"
              :disabled="merlinsUpdating.includes(merlin.id) || merlinSuccessfullyUpdated.includes(merlin.id)"
              :class="[
                'font-semibold', 
                'py-2', 
                'px-4', 
                'border-2', 
                'shadow', 
                'rounded', 
                'text-left', 
                'transition-colors', 
                'duration-400',
                merlinsUpdating.includes(merlin.id) 
                  ? 'bg-orange-500 cursor-not-allowed text-black border-gray-600' 
                  : merlinSuccessfullyUpdated.includes(merlin.id) 
                    ? 'bg-gray-300 text-black border-green-500 cursor-not-allowed' 
                    : 'hover:bg-gray-100 text-gray-800 border-gray-500  border-opacity-70'
              ]"
            >
              {{
                merlinsUpdating.includes(merlin.id) 
                  ? 'Requesting ...' 
                  : merlinSuccessfullyUpdated.includes(merlin.id) 
                    ? 'Requested' 
                    : merlinsUpdating.includes(`Failed ${merlin.id}`) 
                      ? 'Update Failed' 
                      : 'Update'
              }}
            </button>


            </td>
          </template>

        </tr>
        <tr v-if="rowsToExpand.includes(merlin.id)" class="bg-gray-300 mb-10">
          <td colspan="100%" class="pt-5">
            <div class="flex w-full pr-5">
            <div class="flex flex-col pl-5 w-48">
                <!-- <button 
                v-if="expandedRowsStyling[i].main"
                class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 shadow rounded-l text-left">Main</button>
                <button class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 shadow rounded-r text-left" @click="merlinHistory(merlin.id)">History</button> -->
                <button
                  :class="expandedRowsStyling[i].main ? 'bg-gray-100 text-black border-2 border-orange-500  border-opacity-70 border-r-gray-100 rounded-r-none' : 'bg-gray-300 text-gray-800 border-2 border-gray-500 border-r-orange-500  border-opacity-70'"
                  class="hover:bg-gray-100 font-semibold py-2 px-4 shadow rounded-l text-left transition-colors duration-200"
                  @click="dropdownButtonSelector(merlin.id, 'main', i)"
                >
                  Main
                </button>
                <button
                  :class="expandedRowsStyling[i].history ? 'bg-gray-100 text-black  border-2 border-orange-500  border-opacity-70 border-r-gray-100 rounded-r-none' : 'bg-gray-300 text-gray-800 border-gray-500 border-2 border-r-orange-500 border-opacity-70'"
                  class="hover:bg-gray-100 font-semibold py-2 px-4 border border-gray-400 shadow rounded-r text-left rounded-l transition-colors duration-200"
                  @click="dropdownButtonSelector(merlin.id, 'history', i);"
                >
                  History
                </button>

                <button 
                  @click="updateMerin(merlin)"
                  :disabled="merlinsUpdating.includes(merlin.id)"
                  :class="[
                    'font-semibold', 
                    'py-2', 
                    'px-4', 
                    'border-2', 
                    'shadow', 
                    'rounded', 
                    'text-left', 
                    'transition-colors', 
                    'duration-400',
                    merlinsUpdating.includes(merlin.id) 
                      ? 'bg-orange-500 cursor-not-allowed text-black border-gray-600 border-r-orange-500' 
                      : merlinSuccessfullyUpdated.includes(merlin.id) 
                        ? 'bg-grey-300 text-black border-green-500' 
                        : 'bg-grey-300 hover:bg-gray-100 text-gray-800 border-gray-500 border-r-orange-500  border-opacity-70'
                  ]"
                >
                  {{
                    merlinsUpdating.includes(merlin.id) 
                      ? 'Requesting ...' 
                      : merlinSuccessfullyUpdated.includes(merlin.id) 
                        ? 'Requested' 
                        : merlinsUpdating.includes(`Failed ${merlin.id}`) 
                          ? 'Update Failed' 
                          : 'Update'
                  }}
                </button>

                <div class="mb-5 grow bg-zinc-300 border-zinc-300 border-2 border-r-orange-500 border-opacity-70 "></div>
            </div>
            <div v-if="!historiesToDisplay.includes(merlin.id)" class="pl-5 pb-1 bg-gray-100  text-black border-2 border-orange-500 border-opacity-70 rounded-tl-none border-l-gray-100 shadow rounded mr-5 mb-5 flex w-full">
              <ul class="w-5/12 pt-3">
                <li class="flex"><label class="w-32">Code:</label><p class="pl-5 font-semibold">{{ merlin.code }}</p></li>
                <li class="flex"><label class="w-32">Id:</label><p class="pl-5 font-semibold">{{ merlin.id }}</p></li>
                <li class="flex"><label class="w-32">Created At:</label><p class="pl-5 font-semibold">{{ merlin.createdAt }}</p></li>
                <li class="flex"><label class="w-32">Type:</label><p class="pl-5 font-semibold">{{ merlin.type }}</p></li>

              </ul>
            
              <ul class="w-5/12 pt-3">
                <li class="flex"><label class="w-32">Kind:</label><p class="pl-5 font-semibold">{{ merlin.kind }}</p></li>
                <li class="flex"><label class="w-32">Server Ver:</label><p class="pl-5 font-semibold">{{ merlin.server_ver }}</p></li>
                <li class="flex"><label class="w-32">System Ver:</label><p class="pl-5 font-semibold">{{ merlin.system_ver }}</p></li>
                <li class="flex"><label class="w-32">Status:</label><p class="pl-5 font-semibold">{{ merlin.status }}</p></li>
              </ul>
            
              <div class="flex justify-end w-2/12">
                <button @click="closeRowExpand(merlin.id)"
                class="bg-gray-300 fill-gray-800 border-2 border-gray-300 border-l-orange-500 border-b-orange-500  border-opacity-70 shadow rounded h-12 hover:bg-gray-100 hover:fill-black transition-colors duration-200">
                <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 96 960 960" width="42"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>
                </button>
              </div>
            </div>

                <div v-if="historiesToDisplay.includes(merlin.id)" class="pl-5 bg-gray-100 text-black border-2 border-orange-500  border-opacity-70 border-l-gray-100 shadow rounded mr-5 mb-5 pb-2 flex w-full justify-between">
                  <table class="table table-auto w-full mt-3">
                    <thead class="text-bold pb-2">
                      <tr>
                        <th>Tag</th>
                        <th>Value</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody v-for="(deviceHistory, index) in fetchedMerlinHistories" :key="index">
                        <template v-for="entries in deviceHistory" v-if="Object.keys(deviceHistory)[0] === merlin.id">
                          <tr v-for="entry in entries" :key="entry.tag">
                            <td>{{ entry.tag }}</td>
                            <td>{{ entry.value }}</td>
                            <td>{{ entry.created_at }}</td>
                          </tr>
                        </template>
                    </tbody>
                  </table>
                  <div class="flex justify-end w-2/12">
                    <button @click="closeRowExpand(merlin.id)"
                    class="text-gray-800 font-semibold border-2 border-l-orange-500 border-b-orange-500 border-opacity-70 shadow rounded h-12 hover:bg-gray-100  bg-gray-300 hover:fill-white hover:border-orange-500">
                    <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 96 960 960" width="42"><path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z"/></svg>
                    </button>
                  </div>
                </div>
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<style scoped>
.hover-fill:hover rect,
.hover-fill:hover path {
    fill: rgb(249 115 22);
    transition-duration: 250ms;
}

.hover-unfill rect, .hover-unfill path {
  fill: rgb(249 115 22);

}
/* .hover-unfill:hover rect, .hover-unfill:hover path {
    fill: rgb(255, 255, 255);
    transition-duration: 250ms;
} */
</style>
<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "@/store/useGlobalState";

export default defineComponent({
    name: "MerlinTable",
    created(){
      const global = useGlobalState();
    },
    data : function() {
      return {
        merlinData : "",
        error: "",
        global : useGlobalState(),
        srcButton : {
          disabled: false,
          innerText: 'Update Merlin',
        },
        rowsToExpand: [],
        expandedRowsStyling: [],
        fetchedMerlinHistories: [],
        merlinsUpdating: [],
        merlinSuccessfullyUpdated: [],
        historiesToDisplay: [],
        currentSortingMethod : "id",
        currentSortingDirection : true,
            };
    },

    methods :{

      formatHeader(header) {
          let splitHeader = header.includes('-') ? header.split('-')[1] : header;
          return splitHeader.charAt(0).toUpperCase() + splitHeader.slice(1);
      },
      getNestedProperty(obj, prop) {
        const [head, ...tail] = prop.split('-');
        return tail.reduce((prev, cur) => prev && prev[cur], obj[head]);
      },
        reorderTable(headerClicked){
          if(headerClicked == this.currentSortingMethod){
            this.currentSortingDirection = !this.currentSortingDirection;
          }
          if(headerClicked == "id"){
            let sortedData = this.sortThingsById(this.global.merlin.merlinData);
            this.global.merlin.merlinData = sortedData;
          } else {
            let sortedData = this.sortThingsGeneral(this.global.merlin.merlinData, headerClicked);
            this.global.merlin.merlinData = sortedData;
          }
          this.currentSortingMethod = headerClicked;
      },
      sortThingsGeneral(toSort, sortBy) {
          const sortKeys = sortBy.split('-'); // e.g. ['tags', 'kind']

          toSort.sort((a, b) => {
            let valueA = a, valueB = b;

            // Navigate through the nested fields
            for (let key of sortKeys) {
                valueA = valueA[key];
                valueB = valueB[key];
            }

            // Make sure the values are strings for comparison
            valueA = String(valueA);
            valueB = String(valueB);

            if (this.currentSortingDirection) { // Regular order
                if (valueA < valueB) return -1;
                if (valueA > valueB) return 1;
            } else { // Reverse order
                if (valueA < valueB) return 1;
                if (valueA > valueB) return -1;
            }

            return 0;
          });
        return toSort;
      },
      sortThingsById(toSort){
        toSort.sort((a, b) => {
          const [prefixA, suffixA] = a.id.split('-');
          const [prefixB, suffixB] = b.id.split('-');
          
          const numberA = parseInt(suffixA.slice(1), 10);
          const numberB = parseInt(suffixB.slice(1), 10);
          
          if(this.currentSortingDirection == false) {
              if (prefixA < prefixB) return 1;
              if (prefixA > prefixB) return -1;

              // If the prefixes are equal, sort by the number
              return numberB - numberA;
          } else {
              if (prefixA < prefixB) return -1;
              if (prefixA > prefixB) return 1;

              // If the prefixes are equal, sort by the number
              return numberA - numberB;
          }
        });
        return toSort;
      },
      async updateMerin(merlin){

        this.merlinsUpdating.push(merlin.id); // Add merlin to the updating list

        let apiUrl = "/module/database/api/";
        let triggerEvent = "merlin.update";

        let payload = {
          "code": merlin.code,
          "id": merlin.id,
          "wg_net": undefined
        };
  
        let originalText = '';
        if (this.srcButton) {
            this.srcButton.disabled = true;
            originalText = this.srcButton.innerText;
            this.srcButton.innerText = 'Updating ...';
        };
        
        let apiPostUpdateMerlin  = await this.global.axios.post(`/merlin/update_merlin`, {
          name: triggerEvent,
          payload
        })

        if (apiPostUpdateMerlin.status !== 200) {
          // Update the button text
          const index = this.merlinsUpdating.findIndex(id => id === merlin.id);
          if(index !== -1) {
            this.merlinsUpdating[index] = `Failed ${merlin.id}`; 
          }
          return;
        }
      
        // Remove merlin from the updating list once update is successful
        this.merlinsUpdating = this.merlinsUpdating.filter(id => id !== merlin.id);
        this.merlinSuccessfullyUpdated.push(merlin.id);

      },

      async merlinHistory(id){

        if (this.historiesToDisplay.includes(id)) {
          // Stops accidentally spamming fetch requests for merlin history
          return;
        } else {
          this.historiesToDisplay.push(id);
          let historyAlreadyFetched = false;

          // check if history already fetched so as not to double up the same histroy
          this.fetchedMerlinHistories.map((element)=>{
            if (Object.keys(element).includes(id)) {
              historyAlreadyFetched = true;
              return;
            }
          })

          // Fetch the history fot the selected merlin
          if (historyAlreadyFetched === false) {
            try {
              let fetchMerlinHistory = await this.global.axios.post(`/merlin/history_by_id`, {
                id: id
              })
            
              if (fetchMerlinHistory.status !== 200) {
                  console.log(`Error ${fetchMerlinHistory.status}: Merlin history not loaded due to fetch error.`)
                  this.error = `${fetchMerlinHistory.status} error`;
              } else {

                  let recievedMerlinData = fetchMerlinHistory.data.returnedData
              
                  if (recievedMerlinData.length == 0) {
                      this.error = fetchMerlinHistory.status;
                  } else {
                    this.fetchedMerlinHistories.push({ [id] : this.sortObjectsByDate(recievedMerlinData)})
                  }
              }
            } catch (error) {
              console.error(error);
              this.error = "An error occurred while fetching Merlin data.";
            }
          }
        }
      },

      sortObjectsByDate(array) {
        array.sort((a, b) => {
            let dateA = new Date(a.created_at);
            let dateB = new Date(b.created_at);
            return dateB - dateA;  // sorts in descending order (most recent at the top)
        });
        return array;
      }, 

      dropdownButtonSelector(id, buttonType, merlinIndex) {
        const index = this.rowsToExpand.indexOf(id);

        if (index === -1) {
          return;
        }
      
        if (buttonType === 'history' && !this.historiesToDisplay.includes(id)) {
          this.expandedRowsStyling[merlinIndex].main = false;
          this.expandedRowsStyling[merlinIndex].history = true;

          this.merlinHistory(id);
        } else if (buttonType === 'main' && this.historiesToDisplay.includes(id)){
          this.expandedRowsStyling[merlinIndex].main = true;
          this.expandedRowsStyling[merlinIndex].history = false;
          this.historiesToDisplay = this.historiesToDisplay.filter((rowId) => rowId !== id);
        }
      },

      toggleRowExpand(id, toggledRowIndex){

        if (this.rowsToExpand.includes(id)) {
          return
        } else {

          // Hacky code to create a fake expandedRowsStyling entry so if user selects a row to expand that is not in order then the styling will still work
          if (this.expandedRowsStyling.length < this.global.merlin.merlinData.length){
            for (let index = 0; index < this.global.merlin.merlinData.length; index++) {
              this.expandedRowsStyling.push({"main":false, "history":false});
            }
          }
        
          this.expandedRowsStyling[toggledRowIndex].main = true;
          this.expandedRowsStyling[toggledRowIndex].history = false;
          this.rowsToExpand.push(id);

        }
      },
  
      closeRowExpand(id){
        this.rowsToExpand = this.rowsToExpand.filter((rowId) => rowId !== id);
        this.historiesToDisplay = this.historiesToDisplay.filter((rowId) => rowId !== id);
      },
    }
  });
  </script>
  