import { createRouter, createWebHistory } from "vue-router";
import MerlinView from "../views/merlin/MerlinView.vue";
import TallyPointView from "../views/tallypoint/TallypointView.vue";
import AdminView from "../views/shared/AdminView.vue";
import HomeView from "../views/shared/HomeView.vue";

import {useGlobalState} from "../store/useGlobalState";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { authenticated : true }
  },
  {
    path: "/merlin",
    name: "merlin",
    component: MerlinView,
    meta: { authenticated : true }
  },
  {
    path: "/tallypoint",
    name: "tallypoint",
    component: TallyPointView,
    meta: { authenticated : true }
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
    meta: { authenticated : true}
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next)=>{
  const global = useGlobalState();

  let pageName = to.path.substring(1);

  console.log(global.user.userAccess)

  let hasRelevantPageAccess = global.user.userAccess[pageName];
  let isAuthenticatedForEIA = global.user.userAccess.eia.general;

  if (pageName === "admin") {
    hasRelevantPageAccess = global.user.userAccess.eia[pageName];
  } 

  // When the user is logging in to EIA, they will not yet be authenticated. This page is loaded, routing takes place, then they are auth'd
  if (isAuthenticatedForEIA && hasRelevantPageAccess){
    console.log("access to eia + page")
    // If the user is authetnicated and has access to the page, allow them to proceed
    next(); 
  } else if (isAuthenticatedForEIA) {
    // If the user is authenticated but does not have access to the page ...
    console.log("access to eia but not page")
    console.log(to.path);

    if (to.path === '/') {
      // And the user is trying to access the home page, allow them to proceed
      next();
    } else {
      // Redirect them to the home page
      next('/');
    }
  } else {
    // If the user is not authenticated ...
    if (to.path === '/') {
      // And they are trying to access the home page, allow them to proceed to the home page where they will be auth'd from App.vue
      next();
    } else {
      // Redirect to the home page if not authenticated
      next('/');
    }
  }
})

export default router;



