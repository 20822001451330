import { defineStore } from "pinia";
import { useStorage } from '@vueuse/core';
import axios from '@/store/index.js';


export const useGlobalState = defineStore("state", {


    state: ()=> ({
        // We are using the 'useStorage' plugin to add this state to local storage so that the state persists if the user refreshes their page
        user : useStorage('user', {
            username : null, 
            userEmail: null,
            userAccess : {
                eia: {
                    general : false,
                    admin : {
                        general :false,
                        system : false,
                    }
                },
                merlin : false,
                tallypoint :{
                    production : {
                        general : false,
                        admin : false,
                        login : false,
                    },
                    uat : {
                        general : false,
                        admin : false,
                        login : false,
                    }
                }
            },
            jwt: null,
            refreshToken: null,
            loggoutToken : null,
            publicKey: null,
            firstLogin: true,
        }),
        tallypoint : useStorage('tallypoint', {
            production : {
                electionData: [],
                selectedElection: null, // This refers to the current election that is selected in EIA
                activeElection: null, // This refers to the current election that is set as active in Tallypoint
                nonActiveElections: [],
                archievedElectionSelected: null,
            },
            uat : {
                electionData: [],
                selectedElection: null, // This refers to the current election that is selected in EIA
                activeElection: null, // This refers to the current election that is set as active in Tallypoint
                nonActiveElections: [],
                archievedElectionSelected: null,
            },
        }),
        merlin : useStorage('merlin', {
            selectedClientCode: null,
            clientCodes: [],
            merlinData: [],
            selectedHeaders:  ["code", 'id', 'createdAt', 'type','kind', 'server_ver', 'status','system_ver'],
            headerDefaultOrder: ["code", "id", "createdAt", "type", "kind", "server_ver", "system_ver", "status"],
        }),
        axios: axios, // Adding axios instance to global state
    }),
    getters : {
        getUser : (state)=> this.global.user.userAccess.eia.general
    },
    actions:{
        // When triggered (e.g. by logout) this will reset our state object thus logging the user out on the client side
        reset(){
            let tempLoggoutToken = this.$state.user.loggoutToken;
            this.$state.user.loggoutToken = null;
            this.$state.user.jwt= null;
            this.$state.user.username = null;
            this.$state.user.userEmail= null;
            this.$state.user.firstLogin= true;
            this.$state.user.userAccess = {
                eia: {
                    general : false,
                    admin : {
                        general :false,
                        system : false,
                    }
                },
                merlin : false,
                tallypoint :{
                    production : {
                        general : false,
                        admin : false,
                        login : false,
                    },
                    uat : {
                        general : false,
                        admin : false,
                        login : false,
                    }
                }
            };
            this.$state.user.refreshToken= null;
            this.$state.user.publicKey= null;
            this.$state.tallypoint = {
                production : {
                    electionData : [],
                    selectedElection: null,
                    activeElection: null,
                    nonActiveElections: [],
                    archievedElectionSelected: null,
                },
                uat : {
                    electionData : [],
                    selectedElection: null,
                    activeElection: null,
                    nonActiveElections: [],
                    archievedElectionSelected: null,
                },    
            }
            
            this.$state.merlin.selectedClientCode= null;
            this.$state.merlin.clientCodes= [];
            this.$state.merlin.merlinData= [];

            // This is after I started messing with the headers
            // this.$state.merlin.selectedHeaders=  ['tags-code','id', 'createdAt', 'tags-kind', 'tags-server_ver', 'tags-status','tags-system_ver'];
            // this.$state.merlin.headerDefaultOrder= ['code','id', 'createdAt', 'type', 'tags-code', 'tags-kind', 'tags-server_ver', 'tags-system_ver', 'tags-status', 'update'];

            this.$state.merlin.selectedHeaders =   ["code", 'id', 'createdAt', 'type','kind', 'server_ver', 'status','system_ver'];
            this.$state.merlin.headerDefaultOrder =  ["code", "id", "createdAt", "type", "kind", "server_ver", "system_ver", "status", 'update'];


            let redirectUriEncoded = encodeURIComponent(process.env.VUE_APP_EIA_SEVER_URL);

            window.location.href = process.env.VUE_APP_AUTH_SERVER_URL + `realms/eia/protocol/openid-connect/logout?post_redirect_uri=${redirectUriEncoded}` + `&id_token_hint=${tempLoggoutToken}`
        },
        async refreshAccessToken() {
            try {
              const response = await this.$state.axios.post('/system/refresh', {
                refresh_token: this.$state.user.refreshToken, // Send refresh token to your backend
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + this.$state.user.jwt // Include the old JWT in the headers
                }
              }); // Call your refresh endpoint
              return response.data.refreshedToken; // Assuming the response contains new access_token
            } catch (error) {
              throw error;
            }
          }
    }
})






// Darren's approach to global state. Researtch shows that it might be best to stick to pinia rather than vuex

// const obj = {
//     fName: 'darren',
// };

// let fName = '';
// const obj = {
//     get fName() {
//         return fName;
//     },
//     set fName(newVal) {
//         fName = newVal;
//         triggersAllListeners();
//     }
// };


// export const useUserState = defineStore("userState", {
//     state: ()=> ({
//             username : null, 
//             userEmail: null,
//             authenticated : false,
//             userAccess : {
//                 system: false,
//                 merlin : false,
//                 tallypoint : false,
//             },
//             jwt: null,
//             refreshToken: null,
//             publicKey: null,
//             firstLogin: true,
//         })
//     });




