<template>
    <main class="card--halfWidth">
        <h4 class="text-left font-medium">Election Details</h4>
        <div >
          <div class="flex justify-between">
            <p class="font-medium">ID :</p>
            <p>{{global.tallypoint[environment].selectedElection.id}}</p>
          </div>
          <div class="flex justify-between">
            <p class="font-medium">Name : </p>
            <p >{{global.tallypoint[environment].selectedElection.name}}</p>
          </div>
          <div class="flex justify-between">
            <p class="font-medium">Location Name : </p>
            <p>{{global.tallypoint[environment].selectedElection.location_name}}</p>
          </div>
          <div class="flex justify-between">
            <p class="font-medium">Status : </p>
            <p>{{global.tallypoint[environment].selectedElection.status}}</p>
          </div>
        </div>
    </main>
</template>
  
<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "./../../store/useGlobalState";


export default defineComponent({
  name: "ElectionDetails",
  data : function () {
    return {
      global : useGlobalState(),
    };
  },
  props: {
    environment: String, 
  },
});
</script>