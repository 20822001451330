<template>
      <main class="card--fullWidth flex flex-col justify-start items-start">

        <div class="flex flex-row w-full justify-between">
          <div class="w-full flex justify-start" :class='showArchiveOptions ? "border-b-2 border-b-orange-600" : ""'>
            <h4><span class="font-medium">Election Name : </span>{{this.global.tallypoint[environment].activeElection.name}}</h4>
          </div>

          <div class="flex flex-row">

            <!-- If option for 'Archieve Election' has not been selected then show this button  -->
            <button v-if="!showArchiveOptions" id="dropdownDividerButton" @click='archieveButtonPressed = !archieveButtonPressed'
            class="text-center inline-flex items-center whitespace-nowrap" type="button" :class='archieveButtonPressed ? "button--standard--selected" : "button--standard"'>
              Archieve Election <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
              </svg>
            </button>           

            <!-- If option for 'Archieve Election' has been selected then show this button  -->
            <button v-else id="dropdownDividerButton" @click="archieveButtonPressed = !archieveButtonPressed"
            class="button--standard--selectedTab text-center inline-flex items-center whitespace-nowrap" type="button">
              {{createElectionSelected ? "Create New Election" : "Activate Archieved Election"}}<svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
              </svg>
            </button>           


            <!-- Archieve Button choices -->
            <div v-if="archieveButtonPressed"  id="dropdownDivider" class="z-10 mt-16 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                <ul class="py-2 text-sm text-gray-700 hover:bg-orange-100" aria-labelledby="dropdownDividerButton" @click='archiveDropdown' data-value="create">
                  <li>
                    <a href="#" class="block px-4 py-2 text-left">Create New Election</a>
                  </li>
                </ul>
                <div class="py-2 hover:bg-orange-100 " v-if="global.tallypoint[environment].electionData.length > 1"  @click='archiveDropdown' data-value="activateArchieved" >
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 text-left">Activate Archieved Election</a>
                </div>
            </div>

            <!-- Login button and secure connection status symbol -->
            <div class="flex flex-row justify-between w-32 button--standard--login" :class='showArchiveOptions ? "border-b-2 border-b-orange-600 rounded-lg rounded-b-none" : "border-b-2 border-b-gray-600 rounded-lg"' style="padding-right: 4px!important; padding-left: 0px;">
              <button @click="login()" v-if="global.user.userAccess.tallypoint[environment].login" class="w-full" >Login</button>
              <button class="w-full" v-else>Cannot Login</button>

              <div @click="checkSecureConnection()" class="flex border-l-2">
                <button v-if="checking" title="Checking connection to Tallypoint">
                  <svg class="rotation-animation"  xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 96 960 960" width="40" fill="#E8701E"><path d="M196 725q-20-36-28-72.5t-8-74.5q0-131 94.5-225.5T480 258h43l-80-80 39-39 149 149-149 149-40-40 79-79h-41q-107 0-183.5 76.5T220 578q0 29 5.5 55t13.5 49l-43 43Zm280 291L327 867l149-149 39 39-80 80h45q107 0 183.5-76.5T740 577q0-29-5-55t-15-49l43-43q20 36 28.5 72.5T800 577q0 131-94.5 225.5T480 897h-45l80 80-39 39Z"/></svg>
                </button>
                <button v-else-if="checkingError" title="Insecure connection to Tallypoint">
                  <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 96 960 960" width="40"><path d="M196 725q-20-36-28-72.5t-8-74.5q0-131 94.5-225.5T480 258h43l-80-80 39-39 149 149-149 149-40-40 79-79h-41q-107 0-183.5 76.5T220 578q0 29 5.5 55t13.5 49l-43 43Zm280 291L327 867l149-149 39 39-80 80h45q107 0 183.5-76.5T740 577q0-29-5-55t-15-49l43-43q20 36 28.5 72.5T800 577q0 131-94.5 225.5T480 897h-45l80 80-39 39Z"/></svg>
                </button>
                <button v-if="!checking && !checkingError" title="Secure connection to Tallypoint">
                  <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 96 960 960" width="40"><path d="M450 859h60V724h135v-60H510V529h-60v135H315v60h135v135ZM220 976q-24.75 0-42.375-17.625T160 916V482q0-24.75 17.625-42.375T220 422h70v-96q0-78.85 55.606-134.425Q401.212 136 480.106 136T614.5 191.575Q670 247.15 670 326v96h70q24.75 0 42.375 17.625T800 482v434q0 24.75-17.625 42.375T740 976H220Zm0-60h520V482H220v434Zm130-494h260v-96q0-54.167-37.882-92.083-37.883-37.917-92-37.917Q426 196 388 233.917 350 271.833 350 326v96ZM220 916V482v434Z"/></svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Dropdown after 'Archieve Election' option has been selected -->
        <div v-if="showArchiveOptions && global.user.userAccess.tallypoint[environment].admin" class="flex flex-col w-full justify-center border-x-2 border-b-2 border-t-0 rounded-lg rounded-t-none border-x-orange-600 border-b-orange-600" >
          <div v-if="createElectionSelected">
            <CreateElection 
                :environment="environment"
                :create-election-selected="createElectionSelected"
                @update:createElectionSelected="createElectionSelected = $event"
                @trigger-get-election-status="$emit('refreshElectionStatus')" 
                @trigger-close-all-dropdowns="closeAllDropdowns()"
            />
          </div>

          <!--  Activate Archieved Election -->
          <div v-if="previousElectionDropdown" class="general--xPadding">
            <div class="flex flex-row justify-between mb-0 items-end">
              <h4 class="font-medium mb-0">Activate Archieved Election</h4>
              <img :src="SvgClose" alt="my-logo" @click="closeAllDropdowns()" class="w-10 h-10 cursor-pointer"/>
            </div>
            <p class="text-left italic general--topPadding--half">Activating an archieved election will automatically archieve the previously active election. Your user account will be assigned to this election.</p>

            <h4 class="text-left general--topPadding">Archieved Elections :</h4>
            <select @change="activeElectionChanged($event)" v-if="global.tallypoint[environment].electionData.length > 0" class="flex flex-start bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 p-2">
              <option v-for="(election, index) in global.tallypoint[environment].nonActiveElections" :key="election.name" :value="index">{{ election.name }}</option>
            </select>
            <div class="general--topPadding--half general--bottomPadding">
              <button @click="submitChangedActiveElection()" class="button--submit flex flex-start">Submit Change</button>
            </div>
          </div>
        </div>
      </main>
</template>
  
<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "./../../store/useGlobalState";
import CreateElection from "./CreateElection.vue";
import SvgClose from '../../assets/close.svg';



export default defineComponent({
  name: "ActiveElection",
  components: {
    CreateElection,
  },
  data : function () {
    return {
      global : useGlobalState(),
      checkingError: false,
      checking: true,
      showArchiveOptions : false,
      createElectionSelected : false,
      previousElectionDropdown : false,
      archieveButtonPressed: false,
    };
  },
  setup() {
    return {
      SvgClose
    }
  },
  props: {
    environment: String, 
  },
  created(){
    this.checkSecureConnection();
  },
  mounted() {
    // Attach the click event to the document when the component is mounted so that the Archieve Election options hide when the user clicks outside of the dropdown

    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    document.removeEventListener('click', this.handleDocumentClick);
  },
  methods : {
    archiveDropdown(event){
      const value = event.currentTarget.getAttribute('data-value');
      this.showArchiveOptions = true;
      this.archieveButtonPressed = false

      if (value === "create"){
        this.createElectionSelected = !this.createElectionSelected;
        this.previousElectionDropdown = false;
      } else if (value === "activateArchieved"){
        this.previousElectionDropdown = !this.previousElectionDropdown; 
        this.createElectionSelected = false;
      }
      event.stopPropagation();
    },

    handleDocumentClick(event) {
      // This will make the Archieve Election options hide when the user clicks outside of the dropdown

      // IDs to exclude from closing the dropdown
      const excludeIds = ["dropdownDivider", "dropdownDividerButton"];

      // Check if the clicked target or its parents have an ID we want to exclude
      let element = event.target;
      while (element) {
        if (excludeIds.includes(element.id)) {
          return; // Exit early if the clicked element is one of the exclusions
        }
        element = element.parentElement; // Go up the DOM tree
      }
    
      // If we reached here, it means the clicked target was outside the exclusions
      if (!this.$el.contains(event.target)) {
        this.archieveButtonPressed = false;
      }
    },




    closeAllDropdowns() {    
      console.log("close all dropdowns run")  
      this.showArchiveOptions = false;
      this.previousElectionDropdown = false;
      this.createElectionSelected = false;
    },
    activeElectionChanged(event){
      // User input changes the arcieved election selected
      this.global.tallypoint[this.environment].archievedElectionSelected = this.global.tallypoint[this.environment].nonActiveElections[event.target.value];
    },
    async submitChangedActiveElection(){
      // User submits the arcieved election selected to become active

      // Change the acrhieved election selected to active
      this.global.tallypoint[this.environment].archievedElectionSelected.active = true;

      let changeActiveElection = await this.global.axios.post(`/tallypoint/change_active_election`, {
        activeElection : this.global.tallypoint[this.environment].archievedElectionSelected
      }, {
        headers: {
          'X-Target-Environment': this.environment,
        }
      });

      console.log(changeActiveElection);

      if(changeActiveElection.status === 200){

        if  (changeActiveElection.data.oldElectionPublishJobs){
          if (confirm("The election set as 'active' has been changed but the old active election had live published results. Do you want to publish the results of this newly active election instead?")){
            let publishActiveElection = await this.global.axios.post(`/tallypoint/publish_active_website`, {
              activeElection : this.global.tallypoint[this.environment].archievedElectionSelected
            }, {
              headers: {
                'X-Target-Environment': this.environment,
              }
            });
            if (publishActiveElection.status === 200){
              alert("The results of the newly active election have been published")
            } else {
              alert("There was an error whilst publishing the newly active election. The previous active election is still live")
            }
          }
        }
        this.global.tallypoint[this.environment].activeElection = this.global.tallypoint[this.environment].archievedElectionSelected;
        this.showArchiveOptions = false;
        this.previousElectionDropdown = false;
        this.global.tallypoint[this.environment].nonActiveElections = [];
        this.$emit('refreshElectionStatus');
      } else {
        console.log("error changing active election")
      }
    },
    async checkSecureConnection(){
      this.checkingError = false;
      this.checking = true;

      try{
        let fetchSecureConnection = await this.global.axios.post(`/tallypoint/check_secure_status`, {}, {
          headers: {
            'X-Target-Environment': this.environment,
          }
        });

        if (fetchSecureConnection.status === 200) {
          setTimeout(() => {
            this.checkingError = false;
            this.checking = false;
          }, 1000);
        }
        else {
          this.checkingError = true;
          this.checking = false;
        }
      } catch (error) {
        this.checkingError = true;
        this.checking = false;
        console.error("Error fetching election info:", error);
      }
    },
    async login() {
      let fetchLoginLink = await this.global.axios.post(`/tallypoint/get_login_link`, {}, {
          headers: {
            'X-Target-Environment': this.environment,
          }
        });

      console.log(fetchLoginLink.data.link);
      
      window.open(fetchLoginLink.data.link, "_blank");
    }
  },      
});
</script>

<style>
.button-selected {
    background-color: #007BFF; /* Example blue color for the selected state */
    color: white; /* White text color for contrast */
    /* You can add other styles or modify as needed */
}
</style>