<template>
  <main class="main-wrapper">
    <ColumnPicker  v-if="isDataLoaded"/>
    <ClientPicker  v-if="isDataLoaded"/>
    <MerlinTable  v-if="isDataLoaded"/>
  </main>

</template>

<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "@/store/useGlobalState";
import MerlinTable from "@/components/merlin/MerlinTable.vue";
import ClientPicker from "@/components/merlin/ClientPicker.vue";
import ColumnPicker from "@/components/merlin/ColumnPicker.vue";
import axios from '@/store/index.js';
// import apiService from '@/services/apiService';


export default defineComponent({
  name: "MerlinView",
  components: {
    ClientPicker,
    ColumnPicker,
    MerlinTable
  },
  created(){
    const global = useGlobalState();
 
    if(this.global.merlin.selectedClientCode == undefined){
      this.global.merlin.selectedClientCode = this.global.merlin.clientCodes[0];
    };
    this.onLoadDataRequest().then(() => {
      this.isDataLoaded = true;
      this.updateMerlinColumns();
    });
  },
  data : function() {
    return {
      merlinData : "",
      error: "",
      isDataLoaded: false,
      global : useGlobalState(),
    };
  },
  methods :{
    async onLoadDataRequest() {
      try {
        let fetchMerlinInfo = await this.global.axios.post(`/merlin/search_by_code`, {
            code: this.global.merlin.selectedClientCode
        })
      
        if (fetchMerlinInfo.status !== 200) {
            console.log(`Error ${fetchMerlinInfo.status}: Merlin data not loaded through search by code fetch.`)
            this.error = `${fetchMerlinInfo.status} error`;
        } else {
            let recievedMerlinData = fetchMerlinInfo.data.returnedData
        
            if (recievedMerlinData.length == 0) {
                this.error = "No Merlin data returned.";
            } else {
                let sortedData = this.sortThings(recievedMerlinData);
                this.global.merlin.merlinData = sortedData;
            }
        }
      } catch (error) {
        console.error(error);
        this.error = "An error occurred while fetching Merlin data.";
      }

    },
    async updateMerlinColumns(){
      try {
        let fetchMerlinInfo = await this.global.axios.get(`/merlin/merlin_whitelist`)
          if (fetchMerlinInfo.status !== 200){
          } else {
            this.global.merlin.headerDefaultOrder = fetchMerlinInfo.data.returnedData;
          }
      } catch (error) {
        console.error(error);
        this.error = "An error occurred while fetching Merlin data to update column whitelist.";
      }

    },
    sortThings(toBeSorted){
      let sorted = toBeSorted.sort((a, b) => {
        const [prefixA, suffixA] = a.id.split('-');
        const [prefixB, suffixB] = b.id.split('-');
        
        const numberA = parseInt(suffixA.slice(1), 10);
        const numberB = parseInt(suffixB.slice(1), 10);
        
        if (prefixA < prefixB) return -1;
        if (prefixA > prefixB) return 1;

        // If the prefixes are equal, sort by the number
        return numberA - numberB;
      });
      return sorted;
    }
  }
});
</script>
