<template>
    <main>
      <header>
        <TheHeader/>
      </header>
      <h5 class=" pt-52">Please select a page from the navigation bar above</h5>
    </main>
  </template>
  
<script lang="js">
import { defineComponent } from "vue";
import TheHeader from "@/components/shared/TheHeader.vue";
import {useGlobalState} from "./../../store/useGlobalState";

export default defineComponent({
  name: "HomeView",
  components: {
    TheHeader
  },
  data : function () {
    return {
      global : useGlobalState(),
    };
  },
  created(){

    if (!this.global.user.firstLogin && this.global.user.userAccess.eia.general){
      this.global.user.firstLogin = false;
      if (this.global.user.userAccess.merlin) {
        this.$router.push('/merlin');
      } else if (this.global.user.userAccess.tallypoint.production.general || this.global.user.userAccess.tallypoint.uat.general) {
        this.$router.push('/tallypoint');
      } else if (this.global.user.userAccess.eia.admin.general) {
        this.$router.push('/admin');
      }
    }
  }
});
</script>
  