<template>
  <main class="wrapper--multiple" v-if="global.user.userAccess.tallypoint.production.general && global.user.userAccess.tallypoint.uat.general">
    <div class="wrapper__sub" v-if="global.tallypoint.production.selectedElection && global.user.userAccess.tallypoint.production.general">
      <div class="wrapper__sub--header">
        <h2 class="text-left my-0">Production</h2>
      </div>
      <div class="wrapper__sub__sub mt-5">
        <div class="wrapper__sub__sub--header">
          <h3 class="wrapper__sub__sub--header--text">Active Election</h3>
        </div>
        <ActiveElection :environment="`production`" @refreshElectionStatus="getElectionStatus"/>
      </div>
      <div class="wrapper__sub__sub">
        <div class="wrapper__sub__sub--header">
          <h3 class="wrapper__sub__sub--header--text">All Elections</h3>
        </div>
        <AllElectionsSelect :environment="`production`"/>
        <ElectionDetails :environment="`production`"/>
        <ElectionOverview :environment="`production`"/>
      </div>

    </div>

    <div class="wrapper__sub" v-if="global.tallypoint.uat.selectedElection && global.user.userAccess.tallypoint.uat.general">
      <div class="wrapper__sub--header">
        <h2 class="text-left my-0">UAT</h2>
      </div>
      <div class="wrapper__sub__sub mt-5">
        <div class="wrapper__sub__sub--header">
          <h3 class="wrapper__sub__sub--header--text">Active Election</h3>
        </div>
        <ActiveElection :environment="`uat`"  @refreshElectionStatus="getElectionStatus"/>
      </div>
      <div class="wrapper__sub__sub">
        <div class="wrapper__sub__sub--header">
          <h3 class="wrapper__sub__sub--header--text">All Elections</h3>
        </div>
        <AllElectionsSelect :environment="`uat`"/>
        <ElectionDetails :environment="`uat`"/>
        <ElectionOverview :environment="`uat`"/>
      </div>
    </div>
  </main>
  <main class="wrapper" v-else-if="global.tallypoint.production.selectedElection && global.user.userAccess.tallypoint.production.general" >
      <div class="wrapper__sub__sub mt-10">
        <div class="wrapper__sub__sub--header">
          <h3 class="wrapper__sub__sub--header--text">Active Election</h3>
        </div>
        <ActiveElection :environment="`production`" @refreshElectionStatus="getElectionStatus"/>
      </div>
      <div class="wrapper__sub__sub">
        <div class="wrapper__sub__sub--header">
          <h3 class="wrapper__sub__sub--header--text">All Elections</h3>
        </div>
        <AllElectionsSelect :environment="`production`"/>
        <ElectionDetails :environment="`production`"/>
        <ElectionOverview :environment="`production`"/>
      </div>
  </main>
</template>
  
<script lang="js">
import { defineComponent } from "vue";
import TheHeader from "@/components/shared/TheHeader.vue";
import {useGlobalState} from "./../../store/useGlobalState";
import AllElectionsSelect from "@/components/tallypoint/AllElectionsSelect.vue";
import ElectionDetails from "@/components/tallypoint/ElectionDetails.vue";
import ElectionOverview from "@/components/tallypoint/ElectionOverview.vue";
import ActiveElection from "@/components/tallypoint/ActiveElection.vue";



export default defineComponent({
  name: "TallypointView",
  components: {
    TheHeader,      
    AllElectionsSelect,
    ElectionDetails,
    ElectionOverview,
    ActiveElection
  },
  data : function () {
    return {
      global : useGlobalState(),
      electionEnviroments: [],
    };
  },
  mounted() {
  },
  created(){
    this.getElectionStatus();
  },
  methods : {

    async getElectionStatus() {

      if (this.global.user.userAccess.tallypoint.production.general) {
        this.electionEnviroments.push("production");
      }

      if (this.global.user.userAccess.tallypoint.uat.general) {
        this.electionEnviroments.push("uat");
      }

      this.electionEnviroments.forEach(async (environment)=>{
        try {
          let fetchElectionStatus = await this.global.axios.post(`/tallypoint/election_status`, {} ,{ 
            headers: {
              'X-Target-Environment': environment,
            }
          });

          if (fetchElectionStatus.status !== 200) {
            throw new Error(`HTTP error ${fetchElectionStatus.status}`);
          }

          this.processElectionData(fetchElectionStatus.data.returnedData, environment);


          } catch (error) {
          console.error("Error fetching election info:", error);
          }
        })
    },

    processElectionData(data, environment){
        // Reset elections before processing new data
        this.global.tallypoint[environment].electionData = [];
        this.global.tallypoint[environment].nonActiveElections = [];

        data.map((singleElection)=>{
          if(singleElection.active == true){
            this.global.tallypoint[environment].activeElection = singleElection;
            this.global.tallypoint[environment].selectedElection = singleElection;
          
            // This will confirm if the user can login to the active election
            if (singleElection.active_id == singleElection.user_election_id){
              this.global.user.userAccess.tallypoint[environment].login = true;
            } else {
              this.global.user.userAccess.tallypoint[environment].login = false;
            }
          
          } else {
            if (this.global.tallypoint[environment].nonActiveElections.length == 0){
              this.global.tallypoint[environment].archievedElectionSelected = singleElection;
            }
            this.global.tallypoint[environment].nonActiveElections.push(singleElection);
          }
          if(singleElection.status == "open"){
            this.global.tallypoint[environment].electionData.unshift(singleElection);
          } else {
            this.global.tallypoint[environment].electionData.push(singleElection);
          }
        
        })
    }
  }
});
</script>

<style>


.rotation-animation {
  -webkit-animation: rotation 1.5s 60 linear;
  -moz-animation: rotation 1.5s 60 linear;
  -o-animation: rotation 1.5s 60 linear;
  animation: rotation 1.5s 60 linear;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
}

@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg);}
  to   {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
  from {-moz-transform: rotate(0deg);}
  to   {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
  from {-o-transform: rotate(0deg);}
  to   {-o-transform: rotate(359deg);}
}
@keyframes rotation {
  from {transform: rotate(0deg);}
  to   {transform: rotate(359deg);}
}

</style>