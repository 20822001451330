<template v-if="global.tallypoint[environment].selectedElection.active">
    <main class="card--fullWidth" v-if="activeElectionIndex !== null">
        <div class="flex flex-column justify-between items-center">
          <h4><span class="font-medium">Election Name : </span>{{ this.global.tallypoint[this.environment].selectedElection.name }}</h4>


          <div v-if="global.tallypoint[environment].electionData.length > 0" class="flex">
            <select @change="electionSelectedChanged($event)" class="flex flex-start bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 p-2">
              <option value="" disabled selected hidden  class="font-medium">Select A Different Election</option>
              <option v-for="(election, index) in global.tallypoint[environment].electionData" :key="election.name" :value="index">{{ election.name }}</option>
            </select>
          </div>
        </div>
    </main>
</template>
  
<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "./../../store/useGlobalState";


export default defineComponent({
  name: "AllElectionsSelect",
  data : function () {
    return {
      global : useGlobalState(),
      activeElectionIndex: null,
      selectedElectionButton: false,

    };
  },
  props: {
    environment: String, 
  },
  mounted() {
    this.activeElectionIndex = this.global.tallypoint[this.environment].electionData.findIndex(election => election.active);
  },
    methods: {
        electionSelectedChanged(event){
          this.global.tallypoint[this.environment].selectedElection = this.global.tallypoint[this.environment].electionData[event.target.value];
        },
    }
});
</script>