<template>
  <header class="w-100 h-20 border-b-2 border-orange-500 border-opacity-90 bg-gray-600">    
    <nav class="flex justify-between h-20 w-full items-center">
      <div style="padding-left: 5vw;" class="flex flex-col items-start">
        <router-link :to="{name:'home'}" class="text-2xl no-underline text-slate-50">E.I.A</router-link>
        <p class="text-slate-100">{{ global.user.username }}</p>
      </div>
      <div class="inline-block" style="padding-right: 5vw;" v-if="global.user.userAccess.eia.general">
          <router-link v-if="global.user.userAccess.eia.admin" :to="{name:'admin'}" class="pr-10 text-xl text-slate-50 hover:text-white hover:underline hover:decoration-orange-500 hover:decoration-2 hover:underline-offset-4">Admin</router-link>
          <router-link v-if="global.user.userAccess.merlin" :to="{name:'merlin'}" class="pr-10 text-xl text-slate-50 hover:text-white hover:underline hover:decoration-orange-500 hover:decoration-2 hover:underline-offset-4">Merlin</router-link>
          <router-link v-if="global.user.userAccess.tallypoint.production.general || global.user.userAccess.tallypoint.uat.general" :to="{name:'tallypoint'}" class="pr-10 text-xl text-slate-50 hover:text-white hover:underline hover:decoration-orange-500 hover:decoration-2 hover:underline-offset-4">TallyPoint</router-link>
          <button class="text-xl text-slate-50 hover:text-white hover:underline hover:decoration-orange-500 hover:decoration-2 hover:underline-offset-4" @click="logout">Logout</button>
      </div>
      
      <div v-else class="inline-block pr-8">
      </div>
    </nav>
  </header>
</template>

<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "../../store/useGlobalState";

export default defineComponent({
  name: "TheHeader",
  data: function() {
        return {
            global : useGlobalState(),
            error: '',
        };
  },
  methods: {
      async logout() {
        this.global.reset();
      },
  },
});
</script>


<style scoped>
</style>
