import { createApp } from 'vue';
import App from './App.vue';
import './assets/tailwind.css';
import './assets/global.css';
import router from './router';
import { createPinia } from 'pinia';
// import VueSelect from 'vue3-select'
// import 'vue3-select/dist/vue3-select.css'
// import Vue from 'vue';
// import Multiselect from 'vue-multiselect';
import Select2 from 'vue3-select2-component';


// Create the Vue app instance
const app = createApp(App);

// Use the plugins
const pinia = createPinia();
app.use(pinia);
app.use(router);

// Globally register the component
// app.component('VSelect', VueSelect)
// app.component('multiselect', Multiselect)
app.component('Select2', Select2)


// Mount the app
app.mount('#app');
