<template>
  <div v-if="!isGlobalStateLoaded">
    <p>Loading...</p>
  </div>
  <div v-else-if="!isUserAuthorised">
    <p>You are not authorized to access this app.</p>
  </div>
  <div v-else>
    <TheHeader />
    <div>
      <router-view/>
    </div>      
  </div>
</template>

<style>
  @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
  @import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
  @import "../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
  @import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
  @import "../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
  @import "../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css";

  
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100vh;
  }
</style>

<script lang="js">

import Keycloak from 'keycloak-js';
import {useGlobalState} from "./store/useGlobalState";
import TheHeader from './components/shared/TheHeader.vue';
import 'flowbite';


export default {
  components: {
    TheHeader
  },
  data : function () {
    return {
      global : useGlobalState(),
      keycloak: null,
    };
  },
  computed: {
    isGlobalStateLoaded() {
      return this.global && this.global.user && this.global.user.userAccess && this.global.user.userAccess.eia;
    },
    isUserAuthorised() {
      try {
        return this.isGlobalStateLoaded && this.global.user.userAccess.eia.general;
        // return false
      } catch (e) {
        return false;
      }
    }
  },
  injects: ['userState', 'tallypointState'],
  setup(){
    console.log("app.vue loaded")
    const global = useGlobalState();

    console.log(global);
  },
  mounted() {
    const keycloak = new Keycloak({
      "realm": "eia",
      "auth-server-url": process.env.VUE_APP_AUTH_SERVER_URL,
      "ssl-required": "external",
      "resource": "eia",
      "public-client": true,
      "confidential-port": 0,
      "clientId": 'eia'
    });

    keycloak.init({onLoad:"login-required"}).then(() => {


      console.log("Checking user auth with backend");
      console.log(process.env.VUE_APP_EIA_SEVER_API_URL + '/system/userauth')
      // Using a fetch request here rather than an axios request, as axios has not been setup yet, and the JWT has not been added to the axios system yet
      const response = fetch(process.env.VUE_APP_EIA_SEVER_API_URL + '/system/userauth', {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Authorization': 'Bearer ' + keycloak.token
        }
      }).then(async response => {

        let passedJson = await response.json();
        console.log(passedJson);
        console.log(this.global.user.userAccess);

        this.keycloak = keycloak;
        this.global.user.jwt = keycloak.token;
        this.global.user.refreshToken = keycloak.refreshToken;
        this.global.user.loggoutToken = keycloak.idToken;
        this.global.user.username = passedJson.username;
        this.global.user.userEmail = passedJson.userEmail;

        this.global.user.userAccess.eia.general = passedJson.roles.eia;
        this.global.user.userAccess.eia.admin.general = passedJson.roles.eia_admin;
        this.global.user.userAccess.eia.admin.system = passedJson.roles.eia_system_admin;

        this.global.user.userAccess.tallypoint.production.general = passedJson.roles.tallypoint;
        this.global.user.userAccess.tallypoint.production.admin = passedJson.roles.tallypoint_admin;
        this.global.user.userAccess.tallypoint.uat.general = passedJson.roles.tallypoint_uat;
        this.global.user.userAccess.tallypoint.uat.admin = passedJson.roles.tallypoint_uat_admin;

        this.global.merlin.clientCodes = this.addMatchingGroupValues(passedJson, "merlin");
        this.global.user.userAccess.merlin = passedJson.roles.merlin;
        if (!this.global.merlin.selectedClientCode){
          this.global.merlin.selectedClientCode = this.global.merlin.clientCodes[0];
        }


        if (this.global.user.firstLogin && this.global.user.userAccess.eia.general){
          this.global.user.firstLogin = false;
          if (this.global.user.userAccess.merlin) {
            this.$router.push('/merlin');
          } else if (this.global.user.userAccess.tallypoint.production.general || this.global.user.userAccess.tallypoint.uat.general) {
            this.$router.push('/tallypoint');
          } else if (this.global.user.userAccess.eia.admin.general) {
            this.$router.push('/admin');
          }
        }

        // After the logic in App.vue has been run, we will request a new access token from the backend. This way we should always have an up to date access token.
        this.global.refreshAccessToken();


      }).catch(err => {
        console.error(err)
      })
    }).catch(function(err) {
        console.log(err)
        alert('failed to initialize');
    });
  },
  methods : {
    // This function will go through the userGroups array returned by the keycloak token and add the entries to the correct app's global state
    addMatchingGroupValues(sourceJson, parameter) {
      let destinationArray = [];
      sourceJson.groups.forEach(entry => {
        if (entry.hasOwnProperty(parameter) && entry[parameter]) {
          destinationArray.push(entry[parameter]);
        }
      });
      return destinationArray;
    }
  }
};
</script>




