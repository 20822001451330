import { useConfirmDialog } from '@vueuse/core';
import axios from 'axios';
import Vue from 'vue';
import isJwtTokenExpired, { decode } from 'jwt-check-expiry';

const instance = axios.create({
  baseURL: process.env.VUE_APP_EIA_SEVER_API_URL,
  // withCredentials: true,
});

instance.interceptors.request.use((config) => {
  const globalState = require("./useGlobalState").useGlobalState();
  const token = globalState.user.jwt;

  config.headers.Authorization = `Bearer ${token}`;
  config.headers.Accept =  'application/json',
  config.headers['Content-Type'] =  'application/json;charset=utf-8'

  return config;
});

let counter = 0;

// Nesting some of the refresh token functionality inside the axios interceptor, so that everytime a response is 
// given, we can make sure the JWT is refreshed if it is expiring soon, or the user is logged out if it is expired
instance.interceptors.response.use((response) => {
  const directImport = require("./useGlobalState");
  const global = directImport.useGlobalState();
  let isJwtExpiringSoon = isExpiringSoon(10, global.user.jwt);

  // If the JWT has less than 10 minutes left, it will be refreshed. 
  if (isJwtExpiringSoon) {
    global.refreshAccessToken();
  }
  return response;

}, async function (error) {

  console.log(error);

  // If the JWT is expired, the user will be logged out and redirected to the login page
  if (error.response.status === 403) {

    // Ignore the 403 error for the election_overview page, as this route frequently returns 403 errors
    const ignoreUrls = ["election_overview"]

    console.log(error);

    if (!ignoreUrls.includes(error.response.config.url)){
      // console.log("403 error recieved, logging out user and redirecting to login page");
      const globalState = require("./useGlobalState").useGlobalState();
      // globalState.reset();
      tokenHasExpired(globalState.user.jwt);
    }
  }
  return Promise.reject(error);
});

function isExpiringSoon(minutes, token) {
  try {
      const decoded = decode(token);

      if (!decoded || !decoded.payload.exp) {
          throw new Error('Invalid JWT');
      }

      // Get the current time in seconds
      const currentTime = Math.floor(Date.now() / 1000);

      // Calculate the remaining time in minutes
      const remainingTime = (decoded.payload.exp - currentTime) / 60;

      // Return true if the remaining time is less than the specified time
      return remainingTime < minutes;
  } catch (error) {
      console.error('Error decoding JWT:', error);
      return false;
  }
}

function tokenHasExpired(token) {
  try {
    const decoded = decode(token);
    if (!decoded || !decoded.payload.exp) {
      throw new Error('Invalid JWT');
    }

    // Get the current time in seconds
    const currentTime = Math.floor(Date.now() / 1000);

    // Check if the token has expired
    if (decoded.payload.exp < currentTime) {

      if (process.env.VUE_APP_LIVE === "true" || process.env.VUE_APP_LIVE === true) {
        const globalState = require("./useGlobalState").useGlobalState();
        globalState.reset();
        window.location.href = '/blaaaaaaaaaaaaaaaaaaaaaaaaaaa'; // Update this URL to the path of your login page
      } else {
        console.log("JWT has expired, if we were in live mode we would have logged out the user and redirected to the login page");
      }

    }
  } catch (error) {
    console.error('Error checking JWT expiry:', error);

    if (process.env.VUE_APP_LIVE === "true" || process.env.VUE_APP_LIVE === true) {
      window.location.href = '/login'; // Update this URL to the path of your login page
    } else {
      console.log("Caught error with processing JWT, if we were in live mode we would have logged out the user and redirected to the login page");
    }
  }
}

export default instance;
