<template>
  <div>
    <!-- The overlay -->
    <div v-if="showUserPopup" class="overlay"></div>

    <main class="wrapper" :class="{'wrapper--blurred': showUserPopup}">
      <button @click="toggleUserPopup" v-if="global.user.userAccess.eia.admin.system">Create A New User</button>
      <TheAdminUserTable v-if="userList.length > 0"
        :userList="userList" 
        @open-popup="toggleUserPopup()"
        @set-user-profile="setUserProfile($event)"
      />
      <!-- @set-user-profile="userProfile = $event" -->

    </main>

    <!-- TheAdminUserPopup component will be conditionally rendered and appears above the overlay -->
    <TheAdminUserPopup
      v-if="global.user.userAccess.eia.admin.system && showUserPopup"
      :userList="userList"
      :userProfile="userProfile"
      :showUserPopup="showUserPopup"
      :sumbitButtonLabel="userProfile.info.username ? 'Update User' : 'Create User'"
      @close-popup="toggleUserPopup()"
      class="popup"
    />
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "./../../store/useGlobalState";
import TheAdminUserTable from "@/components/shared/TheAdminUserTable.vue";
import TheAdminUserPopup from "@/components/shared/TheAdminUserPopup.vue";
import _ from 'lodash';



export default defineComponent({
  name: "AdminView",
  components: {
    TheAdminUserTable,
    TheAdminUserPopup
  },
  data : function () {
    return {
      global : useGlobalState(),
      userList : [],
      showUserPopup : false,
      emptyUserProfileSnapshot: null, // Property to store the empty snapshot for reset 
      userProfileSnapshot: null, // Property to store the snapshot for furute comparison
      userProfile: {
        info: {
          username: "",  // Required
          credentials:  // Optional
            {
              type: "password",
              value: "",
              temporary: false
            },
          email: "",  // Optional
          firstName: "", // Optional
          lastName: ""      // Optional
        },
        tallypoint : {
          production: {
            hosts: [],
            admin: false, // optional. But if true then there must be at least 1 entry in tallypoint.production.host
            newHost: false,
            publicSitePath: "", // optional. But if newHost is true then this must be filled out
          },
          uat: {
            hosts: [],
            admin: false, // optional. But if true then there must be at least 1 entry in tallypoint.uat.host
            newHost: false, // optional. But if true then there must be at least 1 entry in tallypoint.uat.host
            publicSitePath: "", // optional. But if newHost is true then this must be filled out
          }
        },
        eia: {
          general: true,
          admin: false, // if true then eia.general must be true
          system_admin: false, // if true then eia.admin must be true
        },
        merlin: {
          hosts: [],
          newHost: false,
        },
      }
    };
  },
  mounted() {
    this.pullUsersList();
  },
  created() {
    console.log("created running ...")
    this.emptyUserProfileSnapshot = _.cloneDeep(this.userProfile);
    console.log(this.emptyUserProfileSnapshot);
  },
  methods : {
    async pullUsersList(){
      let fetchUserList = await this.global.axios.post(`/admin/get_users`);
      if (fetchUserList.status === 200) {
        this.userList = fetchUserList.data;
        console.log(fetchUserList.data)
      }
    },
    toggleUserPopup() {
      // If the popup is already shown and the userProfile has been modified,
      // prompt the user to confirm closing the popup
      if (this.showUserPopup && !_.isEqual(this.userProfile, this.userProfileSnapshot)) {
        if (confirm('Changes have been made. Are you sure you want to close the popup?')) {
          this.closePopup();
        }
      } else {
        // Either the popup was not shown or there are no changes in the userProfile
        this.showUserPopup = !this.showUserPopup;

        if (this.showUserPopup) {
          // Take a deep copy of the userProfile when the popup is shown
          this.userProfileSnapshot = _.cloneDeep(this.userProfile);
          document.body.style.overflow = 'hidden';
        } else {
          this.userProfileSnapshot = null; // Reset the snapshot when the popup is closed
          this.closePopup();
          document.body.style.overflow = '';
        }
      }
    },

    setUserProfile(importedUser) {

      // Set the username, firstName, and lastName from the importedUser object
      this.userProfile.info.username = importedUser.username;
      this.userProfile.info.firstName = importedUser.firstName;
      this.userProfile.info.lastName = importedUser.lastName;

      // Set admin properties based on roles in importedUser
      this.userProfile.tallypoint.production.admin = importedUser.roles.includes("tallypoint_admin");
      this.userProfile.tallypoint.uat.admin = importedUser.roles.includes("tallypoint_uat_admin");

      // Set general and admin properties for eia based on roles in importedUser
      this.userProfile.eia.general = importedUser.roles.includes("eia") || importedUser.roles.includes("eia_admin") || importedUser.roles.includes("eia_system_admin");
      this.userProfile.eia.admin = importedUser.roles.includes("eia_admin") || importedUser.roles.includes("eia_system_admin");
      this.userProfile.eia.system_admin = importedUser.roles.includes("eia_system_admin");

      // Set hosts for tallypoint and merlin from the groups in importedUser
      this.userProfile.tallypoint.production.hosts = importedUser.groups.tallypoint || this.userProfile.tallypoint.production.hosts;
      this.userProfile.tallypoint.uat.hosts = importedUser.groups.tallypoint_uat || this.userProfile.tallypoint.uat.hosts;
      this.userProfile.merlin.hosts = importedUser.groups.merlin || this.userProfile.merlin.hosts;


      // Ensure the eia.admin implies eia.general is true
      if (this.userProfile.eia.admin && !this.userProfile.eia.general) {
        this.userProfile.eia.general = true;
      }

    },
    
    closePopup() {
      this.showUserPopup = false;
      this.userProfile = this.emptyUserProfileSnapshot;
      this.userProfileSnapshot = null;
      document.body.style.overflow = '';
    }
  }
});
</script>

<style scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    z-index: 1000; /* for overlay */
  }

  .popup {
    position: fixed; /* Absolute positioning relative to the viewport */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the popup */
    width: 100%; /* or any specific width */
    max-width: 1000px; /* or any maximum width you want */
    min-height: 400px;
    height: 50%;
    z-index: 1001; /* higher than overlay */
    /* Rest of the styling for your popup */
    padding: 20px;
    border-radius: 5px;
  }
</style>