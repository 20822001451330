<template>
  <div class="threequarterWidthBox">

    <div class="flex justify-between">


      <form class="flex" style="width:70vw">
        
          <button @click="showSearchCatergories = !showSearchCatergories ; showColumnHeaders = false" id="dropdown-button" data-dropdown-toggle="dropdown" 
            class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 hover:bg-gray-100 duration-200" 
            :class="[showSearchCatergories ? 'bg-gray-300 text-black border-2 border-orange-500 border-b-gray-300 border-opacity-70 font-medium rounded-t-md rounded-b-none' : 'bg-gray-100 text-gray-800 border border-gray-300 font-light rounded-md',
            showColumnHeaders ? 'border-2 border-opacity-70 border-b-orange-500 rounded-bl-none rounded-br-none' : '']"
            type="button">
            Search categories <svg aria-hidden="true" class="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>


          <div class="relative w-full">
              <input type="search" id="search-dropdown" 
              class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg border-l-gray-50 border-l-2 border border-gray-300 focus:ring-orange-500 focus:border-orange-500" 
              :class="[showSearchCatergories ? 'border-2 border-opacity-70 border-b-orange-500 rounded-bl-none rounded-br-none' : '',
              showColumnHeaders ? 'border-2 border-opacity-70 border-b-orange-500 rounded-bl-none rounded-br-none' : '']"
              placeholder="Search Merlin data..." required
              v-model="searchInput" @input="search"
              >
          </div>
      </form>

      <div class=" h-auto bg-grey-100" style="width: 15vw;" :class="[showColumnHeaders ? 'border-2 border-gray-100 border-b-orange-500 border-opacity-70' : '',
        showSearchCatergories ? 'border-2 border-gray-100 border-b-orange-500 border-opacity-70' : '']
        "></div>

      <div class="flex flex-col"  style="width:25vw;">
        <button @click="showColumnHeaders = !showColumnHeaders ; showSearchCatergories = false" id="dropdown-button" data-dropdown-toggle="dropdown" 
        class="flex-shrink-0 z-10 inline-flex items-center justify-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 duration-200" 
        
        
        :class="[showColumnHeaders ? 'bg-gray-300 text-black border-2 border-orange-500 border-b-gray-300 border-opacity-70 font-medium rounded-t-md rounded-b-none hover:bg-gray-100' : 'bg-gray-100 text-gray-800 border border-gray-300 font-light rounded-md hover:bg-gray-200',
        showSearchCatergories ? 'border-2 border-opacity-70 border-b-orange-500 rounded-b-none' : '']
        "
        type="button">
          Column Headers
          <svg aria-hidden="true" class="w-4 h-4 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        </button>

        <!-- <button @click="resetTableHeaders()" class="bg-gray-100 text-gray-100 border border-gray-300 font-extralight rounded-md">
          <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 96 960 960" width="40"><path d="M288.078 845.999v-50.255h276.563q70.616 0 121.898-45.872t51.282-114.949q0-68.821-51.282-114.693t-121.898-45.872H267.819l118.872 118.873-35.383 35.383-179.384-179.383 179.384-179.384 35.383 35.384-118.872 118.872h296.155q91.564 0 157.833 60.654t66.269 150.166q0 89.769-66.269 150.423-66.269 60.653-157.833 60.653H288.078Z"/></svg>
        </button> -->
      </div>




    </div>
    <div v-if="showSearchCatergories" class="flex flex-row justify-start flex-wrap items-stretch p-2 bg-gray-300 text-black border-2 border-opacity-70 border-orange-500 border-t-0 border-t-gray-100 rounded-md rounded-t-none">
      <button v-for="(singleKey, index) in allSearchKeys" :key="index"
        @click="toggleSearchableKeys(singleKey)" 
        style="min-width: 95px; margin: 2px;"
        class="p-1 rounded-md"
        :class="isKeySearchable(singleKey) ? 'bg-gray-100 border-2 border-opacity-70 border-orange-500 hover:border-gray-500 hover:border-opacity-20 hover:bg-gray-300 duration-200' : 'bg-gray-300 border-2 border-opacity-20 border-gray-500 hover:border-orange-500 hover:border-opacity-70 hover:bg-gray-100 duration-200'">
        {{ singleKey.includes('-') ? singleKey.split('-')[1] : singleKey }}
      </button>
    </div>
    
    <div v-if="showColumnHeaders" class="flex flex-row justify-end flex-wrap items-stretch p-2 bg-gray-300 text-black border-2 border-opacity-70 border-orange-500 border-t-gray-100 border-t-0 rounded-md rounded-t-none">
      <button v-for="(singleKey, index) in allColumnKeys" :key="index"
        @click="toggleHeader(singleKey)"
        style="min-width: 95px; margin: 2px;"
        class="p-1 rounded-md"
        :class="isHeaderSelected(singleKey) ? 'bg-gray-100 border-2 border-opacity-70 border-orange-500  hover:border-gray-500 hover:border-opacity-20 hover:bg-gray-300 duration-200' : 'bg-gray-300 border-2 border-opacity-20 border-gray-500  hover:border-orange-500 hover:border-opacity-70 hover:bg-gray-100 duration-200'">
        {{ singleKey.includes('-') ? singleKey.split('-')[1] : singleKey }}
      </button>
    </div>
  </div>
</template>


<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "@/store/useGlobalState";

export default defineComponent({
    name: "HeaderPicker",
    created(){
      const global = useGlobalState();
      this.loadItems();
      this.originalMerlinData = global.merlin.merlinData;
    },
    data : function() {
      return {
        global : useGlobalState(),
        showColumnHeaders: false,
        showSearchCatergories: false,

        allColumnKeys: [],
        selectedColumnKeys: [],

        allSearchKeys: [],
        selectedSearchKeys: [],

        searchInput: '',
        searchResult: null,
        originalMerlinData: null,
      };
    },

    methods :{
      search() {
        this.global.merlin.merlinData = this.searchMerlinData(this.searchInput);
      },
      searchMerlinData(input) {
        console.log(input);
        if (!input) {
            return this.originalMerlinData;
        } else {
          let results = this.originalMerlinData.filter(item => {
            for (let key in item) {
              console.log(key);
              if (typeof item[key] === 'object') {
                  for (let tagKey in item[key]) {
                    // Checks if nested values contain the search input and if the key is searchable
                    console.log(tagKey);
                    if (item[key][tagKey].toString().includes(input) && this.selectedSearchKeys.includes(key[tagKey])) {
                        return true;
                    }
                  }
              // Checks if values contain the search input and if the key is searchable
              } else if (item[key].toString().includes(input) && this.selectedSearchKeys.includes(key)) {
                  return true;
              }
            }
          return false;
          });
          return results;
        }
      },
      loadItems() {
        console.log("loadItems() run")
        this.allColumnKeys = this.global.merlin.headerDefaultOrder;
        this.allSearchKeys = this.global.merlin.headerDefaultOrder;

        this.selectedColumnKeys = this.global.merlin.selectedHeaders;
        this.selectedSearchKeys = this.global.merlin.headerDefaultOrder;
      },

      collectKeys(obj, parentKey = '') {
        let keys = [];

        for (let key in obj) {
            let newKey = parentKey ? `${parentKey}-${key}` : key;
        
            // We don't want to include the tags in the header picker
            if (key !== "tags"){
              keys.push(newKey);
            }
          
            if (typeof obj[key] === "object" && obj[key] !== null) {
                keys = keys.concat(this.collectKeys(obj[key], newKey));
            }
        }
      
        // Reordering the keys based on the array `this.global.merlin.headerDefaultOrder`
        let orderedKeys = this.global.merlin.headerDefaultOrder.filter(key => keys.includes(key));
        let unorderedKeys = keys.filter(key => !this.global.merlin.headerDefaultOrder.includes(key));
      
        // Combining the ordered and unordered keys
        keys = [...orderedKeys, ...unorderedKeys];
      
        // Adding the update key to the end of the array as it is not contained in the source object
        return keys;  
      },

      removeValueFromArray(array, value) {
          const clonedArray = [...array];
          const index = clonedArray.indexOf(value);
          if (index > -1) {
              clonedArray.splice(index, 1);
          }
          return clonedArray;
      },

      isHeaderSelected(header) {
        const isSelected = this.global.merlin.selectedHeaders.includes(header);
        return isSelected;
      },

      isKeySearchable(key) {
        if (this.selectedSearchKeys.includes(key)){
          return true;
        } else {
          return false;
        }
      },

      toggleSearchableKeys(key){
        console.log(this.selectedSearchKeys);
        if (this.selectedSearchKeys.includes(key)){
          let postRemovalArray = this.removeValueFromArray(this.selectedSearchKeys, key);
          this.selectedSearchKeys = postRemovalArray;
        } else {
          this.selectedSearchKeys.push(key);
        }
      },
      toggleHeader(header) {
        if (this.isHeaderSelected(header)) {
          const index = this.global.merlin.selectedHeaders.indexOf(header);
          if (index !== -1) {
            this.global.merlin.selectedHeaders.splice(index, 1);
          }
        } else {
          this.global.merlin.selectedHeaders.push(header);
        }
        this.$emit('update:dropdownTicked', this.global.merlin.selectedHeaders);
      },
    }
});
</script>

