<template>
    <main class="card--halfWidth">
        <section class="card">
            <h4 class="font-medium text-left">Live Precinct Info</h4>

            <p v-if="electionsByActivity.length < 1" class="italic text-left">No precinct infomation available</p>

            <div v-for="election in electionsByActivity" :key="'e-'+election.id" class=" text-left">
                <h4 class="border-b border-neutral-600 mb-0">
                    {{election.location}} / {{election.name}}
                    <span
                        v-if="logs[election.id].hasRecentActivity"
                        class="text-sm inline-block ml-5 text-neutral-800 rounded px-2"
                        :title="logs[election.id].lastActivity"
                    >
                        Active {{timeAgo(logs[election.id].lastActivity)}}
                    </span>

                    <label class="float-right text-sm">
                        <input type="checkbox" v-model="openElections[election.id]" /> Show
                    </label>
                </h4>

                <div v-if="openElections[election.id]">
                    <div class="flex flex-col">
                        <div class="flex flex-col items-start legend">
                            <span class="inline-block">Precinct legend:</span>
                            <div><div style="background:gray;"></div> Not Ready</div>
                            <div><div style="background:green;"></div> Ready</div>
                            <div><div style="background:orange;"></div> Entering results</div>
                            <div><div style="background:#0072ff;"></div> Completed</div>
                        </div>
                    </div>
                    <div class="election-meta">
                        Election status: {{election.status}}.
                        Precincts completed: {{numCompletedPrecincts(election)}} / {{numPrecinctsInElection(election)}}.
                    </div>

                    <div class="flex flex-col">
                        <div class="district-inline" v-for="district in election.districts" :key="'d-'+district.id">
                            <div class="district-inline-title pr-2">{{district.name}}</div>

                            <div
                                v-for="p in district.precincts" :key="'p-'+p.key"
                                class="precinct"
                                :class="{
                                    'precinct-ready': !!p.ready,
                                    'precinct-completed': p.status ==='completed',
                                    'precinct-withresults': p.hasResults,
                                }"
                            >
                                <span class="precinct-key">{{p.key}}</span>
                            </div>
                        </div>
                    </div>


                    <button @click="toggleOpenLogs(election.id)" class="cursor-pointer button--standard w-full general--topMargin">Live election activity</button>
                    <div v-if="openLogs[election.id]" class="bg-neutral-300">
                        <div v-if="logs[election.id].entries.length === 0">Waiting for something to happen...</div>
                        <div
                            v-for="log in logs[election.id].entries.slice(0, 5)"
                            :key="log.key"
                            class="border-b border-dashed border-gray-600 py-1"
                        >
                            <span class="inline-block bg-white px-2 mr-3 text-sm">{{log.created_at.toLocaleTimeString()}}</span>
                            <span class="inline-block">{{log.line}}</span>
                            <span class="inline-block text-sm">
                                <span
                                    v-for="tag in log.tags" :key="`tag-${log.key}-${tag[0]}`"
                                    class="inline-block bg-white px-1 ml-1"
                                >
                                    {{tag[0]}}={{tag[1]}}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<style scoped>
    .legend {
        @apply bg-orange-100;
        padding: 1em;
        margin-bottom: 2em;
    }
    .legend > div {
        display: inline-block;
        margin-right: 1em;
    }
    .legend > div > div {
        width: 1em;
        height: 1em;
        display: inline-block;
    }
    .election-meta {
        margin-bottom: 0.5em;
    }

    .district-inline {
        display: inline;
        margin-right: 1em;
    }
    .district-inline:hover {
        @apply bg-orange-100;
    }
    .district-inline > * {
        display: inline-block;
    }
    .precinct {
        display: inline-block;
        /*
        width: 70px;
        height: 70px;
        */
        margin: 2px;

        background: gray;
    }
    .precinct-ready {
        background: green;
    }
    .precinct-withresults {
        background: orange;
    }
    .precinct-completed {
        background: #0072ff;
    }

    .precinct-key {
        display: inline-block;
        margin: 3px;
        background: #ffffff40;
        padding: 1px 5px;
        border-radius: 2px;
        font-size: 0.8em;
    }
</style>
  
<script lang="js">
import { defineComponent } from "vue";
import {useGlobalState} from "./../../store/useGlobalState";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US');

export default defineComponent({
    name: "ElectionOverview",
    props: {
        environment: String, 
    },

    data : function () {
      return {
          global : useGlobalState(),
          overviewData : null,
          selectedElection: null,
          rs: [],
          auditEntries: {/*electionId:{entries:[]}*/},
          openLogs: {/*electionId: true*/},
          openElections: {/*electionId: true*/},
      };
    },
    created(){
      this.refresh();
      this.refreshTmr = setInterval(() => {
          this.refresh();
      }, 3000);
    //   this.$state.$on('serverevent.audits.log', (entry) => {
    //         let eId = entry.tags.election_id;
    //         if (eId) {
    //             if (!this.auditEntries[eId]) {
    //         this.auditEntries[eId] = {
    //     entries: [],
    // };
    //             } 
    //             this.auditEntries[eId].entries.push(entry);
    //         }
    //     });
    },
    destroyed() {
        clearInterval(this.refreshTmr);
    },

    computed: {
        logs() {
            let precincts = this.precincts;
            let elections = {};
            for (let election of Object.values(this.elections)) {
                let entries = [];
                (this.auditEntries[election.id]?.entries || []).forEach(e => {
                    let tags = [];
                    let tagRewrite = {  
                        user_id: null,
                        user_username: 'User',
                        user_admin: 'Admin',
                        precinct_id: (val) => { precincts[val]?.name },
                        user_agent: null,
                        election_id: null,
                        host: null,
                    };

                    for (let prop in e.tags) {
                        if (typeof tagRewrite[prop] === 'string') {
                            tags.push([tagRewrite[prop], e.tags[prop]]);
                        } else if (typeof tagRewrite[prop] === 'function') {
                            let val = tagRewrite[prop](e.tags[prop]);
                            if (val !== null && typeof val !== 'undefined') {
                                tags.push([tagRewrite[prop], val]);
                            }
                        } else if (tagRewrite[prop] === null) {
                            continue;
                        } else {
                            tags.push([prop, e.tags[prop]]);
                        }
                    }

                    entries.unshift({
                        created_at: new Date(e.created_at),
                        line: e.message,
                        tags,
                        key: JSON.stringify(e),
                    });
                });
                elections[election.id] = {
                    hasRecentActivity: entries[0] && (entries[0].created_at.getTime() > Date.now() - 3600*1000),
                    lastActivity: entries[0] ? entries[0].created_at : null,
                    entries,
                };
            }
            return elections;
        },
        precincts() {
            let ret = {};
            this.rs.forEach(row => {
                if (!row.precinct_id) {
                    return;
                }

                ret[row.precinct_id] = {
                    name: row.precinct_name,
                    key: row.precinct_key,
                    id: row.precinct_id,
                };
            });
            return ret;
        },
        elections() {
            let ret = {};
            this.rs.forEach(row => {
                ret[row.election_id] = ret[row.election_id] || {
                    id: row.election_id,
                    location: row.election_location,
                    name: row.election_name,
                    status: row.election_status,
                    districts: {},
                };

                let election = ret[row.election_id];

                election.districts[row.district_id] = election.districts[row.district_id] || {
                    id: row.district_id,
                    name: row.district_name,
                    precincts: [],
                };

                let district = election.districts[row.district_id];

                district.precincts.push({
                    id: row.precinct_id,
                    key: row.precinct_key,
                    name: row.precinct_name,
                    ready: !!row.precinct_ready,
                    status: row.precinct_status,
                    hasResults: !!row.started_entering_results,
                });
            });

            return ret;
        },
        electionsByActivity() {
            let active = {};
            let inactive = {};
            let logs = this.logs;

            for (let e in this.elections) {
                if (logs[e].hasRecentActivity) {
                    active[e] = this.elections[e];
                } else {
                    inactive[e] = this.elections[e];
                }
            }

            return [...Object.values(active), ...Object.values(inactive)];
        },
    },

    methods: {
      timeAgo(d) {
            return timeAgo.format(d);
        },

      // This should be corrrect
      async refresh() {
            this.selectedElection = this.global.tallypoint[this.environment].selectedElection
            let resp = await this.global.axios.post(`/tallypoint/election_overview`, {
              election_id : this.global.tallypoint[this.environment].selectedElection.id
            }, {
              headers: {
              'X-Target-Environment': this.environment,
            }});
            
            if (resp.status !== 200) {
                return
            }

            this.rs = resp.data.returnedData;

            // Update some data that needs to know of election IDs
            if (typeof this.openLogs[this.global.tallypoint[this.environment].selectedElection.id] === 'undefined') {
                this.openLogs[this.global.tallypoint[this.environment].selectedElection.id] = false;
            }

            if (typeof this.openElections[this.global.tallypoint[this.environment].selectedElection.id] === 'undefined') {
                // Hide the election by default to force the user to only show what they're
                // interested in. Everything at once can be overwhelming
                this.openElections[this.global.tallypoint[this.environment].selectedElection.id] = false;
            }
        
        },
      numPrecinctsInElection(election) {
          let cnt = 0;
          Object.values(election.districts).forEach(d => {
              cnt += d.precincts.length;
          });
          return cnt;
      },
      numCompletedPrecincts(inp) {
          let cnt = 0;
          if (inp.districts) {
              Object.values(inp.districts).forEach(d => {
                  cnt += d.precincts.filter(p => p.status === 'completed').length;
              });
          } else {
              cnt = inp.filter(p => p.status === 'completed').length;
          }
          return cnt;
      },
      toggleOpenLogs(electionId) {
        this.openLogs[electionId] = !this.openLogs[electionId];
        },
    },
});
</script>